import { Injectable } from '@angular/core';
import { C_CategoryInfo } from '@models/C_CategoryInfo';
import { LiveSport } from '@models/sportbook';
import { SportHandleList } from '@utils/sportHandleList';
import { SportNav, SportNavItem } from 'libs/sports/src/lib/models';

@Injectable({ providedIn: 'root' })
export class livePageUtils {
  setSportsNav(current, selectedSport?) {
    let sportsNav: SportNavItem[] = [];
    current.forEach((sport) => {
      sportsNav.push(new SportNavItem(sport.Name, sport.NodeId, sport.SportHandle, false, sport, sport.SportId));
    });
    // Add VideoLive Navbar item
    const videoLiveSport = current.find((sport) => sport.Events.find((ev) => ev.StreamingEnabled));
    if (videoLiveSport) {
      const videoLiveSportNav = {
        Name: SportHandleList.videoliveLiteral,
        Events: this.setVideoLiveSportNav(current),
        SportHandle: SportHandleList.videolive,
        NodeId: '00',
        SportId: '00',
        ParentNodeId: '-1'
      };

      sportsNav.unshift(
        new SportNavItem(
          SportHandleList.videoliveLiteral,
          '00',
          SportHandleList.videolive,
          false,
          videoLiveSportNav,
          '00'
        )
      );
    }
    // Order Navbar items
    sportsNav = this.orderSportsNav(sportsNav);
    const selectedSportsNav: SportNavItem = selectedSport
      ? this.findSelectedSport(selectedSport, sportsNav)
      : videoLiveSport
        ? sportsNav[1]
        : sportsNav[0];
    return {
      sportsNav,
      selectedSportsNav
    };
  }

  findSelectedSport(selectedSport, sportsNav) {
    return sportsNav.find((sportNav) => sportNav.SportHandle == selectedSport.SportHandle);
  }

  orderSportsNav(categories: SportNavItem[]) {
    const excludedCategories = [SportHandleList.videolive, SportHandleList.soccer, SportHandleList.tennis];
    const videoliveCat = categories.find((i) => i.SportHandle == SportHandleList.videolive);
    const soccerCat = categories.find((i) => i.SportHandle == SportHandleList.soccer);
    const tennisCat = categories.find((i) => i.SportHandle == SportHandleList.tennis);
    const filteredCategories = categories.filter((i) => !excludedCategories.includes(i.SportHandle));

    if (tennisCat) filteredCategories.unshift(tennisCat);
    if (soccerCat) filteredCategories.unshift(soccerCat);
    if (videoliveCat) filteredCategories.unshift(videoliveCat);
    return filteredCategories;
  }

  setVideoLiveSportNav(data) {
    return data.flatMap((sport) => sport.Events.filter((ev) => ev.StreamingEnabled));
  }

  getCategories(navSports: SportNav) {
    const categories = [];
    let selectedCategory = null;
    const currentSportWEvents = navSports.sportsNav.find(
      (item) => item.SportHandle == navSports?.selectedSportsNav?.SportHandle
    );

    if (!currentSportWEvents) return { categories, selectedCategory };

    currentSportWEvents.Data.Events.forEach((event) => {
      if (!event.LeagueName) return;

      const index = categories.findIndex((category) => category.term === event.CountryCode);
      if (index === -1) {
        const newCategory: C_CategoryInfo = new C_CategoryInfo(
          event.NodeId,
          false,
          event.CountryName,
          null,
          event.CountryCode,
          event.SportHandle,
          null,
          event.CountryName
        );
        newCategory.numGames = 1;
        categories.push(newCategory);
        return;
      }

      categories[index].numGames++;
    });

    const all: C_CategoryInfo = new C_CategoryInfo(
      '99',
      false,
      'Todos',
      null,
      '',
      navSports.selectedSportsNav.SportHandle
    );
    categories.unshift(all);
    selectedCategory = all;
    return {
      categories,
      selectedCategory
    };
  }

  getEvents(selectedSport, prevSelectedSport, categories) {
    const sport: LiveSport = {
      Name: selectedSport?.Data?.Name,
      NodeId: selectedSport?.Data?.NodeId,
      SportId: selectedSport?.Data?.SportId,
      SportHandle: selectedSport?.Data?.SportHandle,
      Leagues: []
    };

    if (categories.categories.length < 1) return sport;

    let Events = selectedSport?.Data?.Events;
    if (prevSelectedSport) {
      const updatedSelectedSport = this.updateOddsInLiveEvents(prevSelectedSport, selectedSport);
      Events = updatedSelectedSport.Events;
    }

    Events.forEach((event) => {
      const parentId = event.ParentNodeId;
      // Check if the league already exists
      let league = sport.Leagues.find((i) => i?.ParentNodeId === parentId);
      if (!league) {
        // Create a new league object with an Events array if it doesn't exist
        league = {
          ParentNodeId: parentId,
          LeagueName: event.LeagueName,
          CountryCode: event.CountryCode,
          Events: []
        };
        sport.Leagues.push(league);
      }
      // Push the event to the league's Events array
      league.Events.push(event);
    });

    return sport;
  }

  updateOddsInLiveEvents(prevLiveEvent, currentLiveEvent) {
    const findEventById = (events, id) => events.find((event) => event?.NodeId === id);
    const updateResults = (currentResults, previousResults) =>
      currentResults.map((currResult) => {
        const prevResult = findEventById(previousResults, currResult.NodeId);
        return {
          ...currResult,
          Odd: currResult.Odd,
          upOdd: prevResult ? prevResult.Odd < currResult.Odd : false,
          downOdd: prevResult ? prevResult.Odd > currResult.Odd : false
        };
      });
    const updateGames = (currentGames, previousGames) =>
      currentGames.map((currGame) => {
        const prevGame = findEventById(previousGames, currGame.NodeId);
        if (!prevGame) return currGame;
        return {
          ...currGame,
          Results: updateResults(currGame.Results, prevGame.Results)
        };
      });
    const updateEvents = (currentEvents, previousEvents) =>
      currentEvents.map((currEvent) => {
        const prevEvent = findEventById(previousEvents, currEvent.NodeId);
        if (!prevEvent) return currEvent;
        return {
          ...currEvent,
          Games: updateGames(currEvent.Games, prevEvent.Games)
        };
      });
    return {
      ...currentLiveEvent,
      Events: updateEvents(currentLiveEvent.Data.Events, prevLiveEvent.Data.Events)
    };
  }
}
