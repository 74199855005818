/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable eqeqeq */
import {
  Component,
  Input,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  inject,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  MenuController,
  AlertController,
  ModalController,
  IonicModule,
  NavController,
  PopoverController
} from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

//Models
import { C_SearchResult } from '../../../models/C_SearchResult';
import { C_Horses } from '../../../models/C_Horses';
import { C_EventLive } from '../../../models/C_EventLive';
import { C_Event } from '../../../models/C_Event';
import { LicenseTypes } from '../../../models/MasterData';
import { EventTypes } from '../../../models/TrackingEvents';

//Services
import { EventsService } from '../../../providers/EventsService';
import { MessageService } from '../../../providers/MessageService';
import { NavService } from '../../../providers/NavService';

import { TrackingService } from '../../../providers/TrackingService';
import { UserService } from '../../../providers/UserService';
import { UserServiceMSO } from '../../../providers/UserServiceMSO';

import { ScriptService } from '../../../providers/ScriptService';
import { BalancesService } from '../../../providers/BalancesService';
import { TicketService } from '../../../providers/TicketService';
import { SearchService } from '../../../providers/SearchService';
import { SportService } from '../../../providers/SportService';
import { Utils } from '../../../utils/Utils';
import { PipesModule } from '../../../pipes/pipes.module';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { ParseMoney } from '../../../pipes/parseMoney';
import { CASINO_PATHS, MSO_PATHS, PAYMENTS_PATHS, SPORTS_PATHS } from '../../../lib/constants/routes';
import { Login } from '../../../pages/mso/login/login';
import { DeviceService } from '../../../services/device.service';
import { filter, interval, Subscription, take } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { FormsModule } from '@angular/forms';
import { TypeLogout } from '@models/I_DecouplingLogout';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, PipesModule, TranslateModule, ParseMoney, FormsModule],
  selector: 'codere-navbar-pc',
  templateUrl: './codere-navbar-pc.html',
  styleUrls: ['./codere-navbar-pc.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CodereNavbarPc implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: any;
  @Input() titulo: string | undefined;
  idInterval: number;
  resultsPositionLeft: string | undefined;
  searchFocus = false;
  searchFocusActive = false;
  searchInputModel = '';
  searchResults: any[] = [];
  searchResultsLoading = false;
  searchSubMenuClosed = true;
  timeColombia: string;
  isAlertAr: boolean;
  timeAlertAr: number;
  closeAlertAr: number;
  messageCount: number | null | undefined;
  userSubMenuClosed = true;
  headerImg: {
    imgSrc: string;
    imgSrcCas: string;
    imgSrcSport: string;
    imgClass: { sport: string; casino: string; common: string };
  } = {
    imgSrc: '',
    imgSrcCas: '',
    imgSrcSport: '',
    imgClass: { sport: '', casino: '', common: '' }
  };
  isCasinoUrl: boolean = false;
  isTesting = false;
  globalVars!: GlobalVarsModel;
  localTime: string;
  readonly licenseType = LicenseTypes;

  //#region Inject Services
  menu = inject(MenuController);
  translate = inject(TranslateService);
  sportService = inject(SportService);
  alertController = inject(AlertController);
  navController = inject(NavController);
  router = inject(Router);
  utils = inject(Utils);
  balancesService = inject(BalancesService);
  ticketService = inject(TicketService);
  events = inject(EventsService);
  modalCtrl = inject(ModalController);
  navService = inject(NavService);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  //  ss= inject(UtilsMyBetsService);
  searchService = inject(SearchService);
  messageService = inject(MessageService);
  scriptService = inject(ScriptService);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  cdr = inject(ChangeDetectorRef);
  deviceService = inject(DeviceService);
  popoverController = inject(PopoverController);

  //#endregion
  isMobile = this.deviceService.isMobile();
  popoverName: string;
  hideHeaderSearchFunctionality = true;

  @Output() navigateToTicket: EventEmitter<void> = new EventEmitter();

  private subscriptions: Subscription[] = [];
  constructor() {
    this.subscriptions.push(
      this.newBaseService.getVars.subscribe((data) => {
        this.globalVars = data;
      }),
      this.balancesService.getPopoverName().subscribe((data) => {
        this.popoverName = data;
      })
    );
    this.setTimeCol();
    this.isAlertAr = false;
    this.timeAlertAr = 0;
    this.closeAlertAr = 0;
    this.setTimeCol().then((value) => {
      if (value) this.timeColombia = value;
    });
    this.idInterval = window.setInterval(() => {
      this.setTimeCol().then((value) => {
        if (value) this.timeColombia = value;
      });
    }, 300);

    this.utils.openAccder$.subscribe((data) => {
      //** TODO */
      // this.openModalLogin({ activeSection: 2 })
      //Debería ir a confirmar contraseñas
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit() {
    if (this.globalVars.FEATURES.messageServiceEnabled) {
      this.messageService.messageCount.subscribe((data) => {
        this.messageCount = data;
      });
    }

    if (this.globalVars.FEATURES.MSO_LocalDateTimer) {
      this.subscriptions.push(
        interval(1000).subscribe(() => {
          this.localTime = this.utils.getLocalTime();
        })
      );
    }
    if (this.globalVars.FEATURES.MSO_NewReBranding) {
      this.subscriptions.push(
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
          this.isCasinoUrl = event['url'].startsWith(`/${CASINO_PATHS.BASE}`);
        })
      );
    }
    this.changeLogo();
    this.hideHeaderSearchFunctionality = !this.globalVars.FEATURES.SPO_HideHeaderSearchFunctionality;
  }

  navigate(component: any, params?: any, left = false) {
    if (left) {
      this.navService.updateNavLeft(component.componentName);
    }

    const item = this.navService.navSectionsLeft.find(
      (itemN) => itemN.componentName === component.componentName && itemN.translate === component.translate
    );
    params = item && item.params ? item.params : undefined;

    if (item.path === 'casino/CasinoenVivoPage') {
      item.path = `${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoEnVivoPage}`;
    }

    const path: string = item.path;
    if (path.includes('casino')) {
      this.router.navigate([`/${path}`], { queryParams: params });
    } else {
      this.router.navigate([`/${path}`], {
        state: { params: params }
      });
    }
  }

  goHomePage() {
    this.router.navigateByUrl('/HomePage');
  }

  async navigateDeposits() {
    if (this.globalVars.FEATURES.DepOnlineEnabled || this.globalVars.FEATURES.DepLocalEnabled) {
      if (this.isMobile) {
        this.router.navigateByUrl('/HomePage');
      } else {
        this.globalVars.rootScope.openModalOrPage(
          `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
          {},
          true,
          'modalCashier is-modal'
        );
      }
      this.trackingService.trackEvent(['ClickOpenCashier', 'TopRibbon', '', 'event']);
    } else {
      this.utils.alert(false, '', this.translate.instant('m_menu_serverFail'), 'OK');
    }
  }

  async openModalLogin(params = null) {
    this.globalVars.afterLoginUrl = null;

    this.globalVars.rootScope.openLogin(() => {
      this.events.publish('navbarpc:refresh');
    });
  }

  backToHome() {
    this.router.navigateByUrl('/');
  }

  refreshBalance() {
    this.trackingService.track({ eventType: EventTypes.RefreshBalance, description: 'Refrescar saldo' });
    this.balancesService.refreshBalance();
  }

  showBalanceDetails(event: any): void {
    if (!this.balancePopoverAvailable()) return;
    this.trackingService.track({
      eventType: EventTypes.ShowBalanceDetails,
      description: 'Muestra detalles del balance'
    });
    this.events.publish('popover:open', this.balancesService);
    this.balancesService.showBalanceDetails(event, this.popoverController);
  }

  // TODO No se utiliza
  // redirect(item: any) {
  //   //** TODO */
  //   // this.globalVars.gNav.setRoot(NowLiveDetailPage, { item: item });
  // }

  openMisApuestas() {
    const filterParam = { dateValue: 0, typeValue: 1 };
    this.navigateToTicket.emit();
    this.router.navigate([SPORTS_PATHS.MisApuestasPage], { state: { params: filterParam } });
  }

  goUserMessagesPage() {
    this.router.navigateByUrl(`/${MSO_PATHS.BASE}/${MSO_PATHS.UserMessagesPage}`);
  }
  goContactPage() {
    this.router.navigateByUrl(`/${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`);
  }

  doLogout() {
    //logout
    this.events.publish('user:logout', TypeLogout.MANUAL);
    this.events.publish('navbarpc:refresh');
    // this.ticketService.clearOrder().subscribe(
    //   () => {
    //     this.globalVars.freeBetIdSelected = '';
    //     // this.ticketService.init();
    //     this.events.publish('user:logout');
    //     this.events.publish('navbarpc:refresh');
    //   },
    //   () => {
    //     this.globalVars.freeBetIdSelected = '';
    //     // this.ticketService.init();
    //     this.events.publish('user:logout');
    //     this.events.publish('navbarpc:refresh');
    //   }
    // );
  }

  openRegistro() {
    this.globalVars.rootScope.openRegistro();
  }

  isActive(component: any) {
    const url = this.router.url;
    const currentRoute = this.router.url?.split('?')[0]?.toLowerCase();
    const componentRoute = component.path?.toLowerCase();
    // Check whether Deportes btn link should be active when navigating between selected pages
    const showActiveDeportesRoutes = [
      SPORTS_PATHS.EventoPage,
      SPORTS_PATHS.EventoSpecialPage,
      SPORTS_PATHS.HorsesMarketPage,
      SPORTS_PATHS.HorsesPage,
      SPORTS_PATHS.MercadosPage,
      SPORTS_PATHS.NowLiveDetailPage,
      SPORTS_PATHS.SportCountriesPage,
      SPORTS_PATHS.CreaTuApuestaPage,
      SPORTS_PATHS.SpecialTeamPage,
      SPORTS_PATHS.TodayPage
    ];
    if (
      showActiveDeportesRoutes.some((path) => currentRoute.toUpperCase().includes(path.toUpperCase())) &&
      componentRoute === 'homepage'
    ) {
      return true;
    }
    if (currentRoute.includes('casino')) {
      const lobby = component.params?.lobby;
      const parseUrl = url.replace(/%20/g, ' ');
      if (parseUrl.includes(lobby)) {
        return true;
      }
    } else {
      return currentRoute.includes(componentRoute);
    }
  }

  getFeature(component: any) {
    const feature =
      // eslint-disable-next-line no-nested-ternary
      component?.feature === 'false' ? false : component?.feature === undefined ? false : component?.feature;
    return feature;
  }

  toggleSubMenu(submenu: string, toggle?: any) {
    switch (submenu) {
      case 'search':
        this.searchSubMenuClosed = toggle.value;
        this.resultsPositionLeft = this.searchInput.nativeElement.getBoundingClientRect().right - 400 + 'px';
        break;
    }
  }

  toggleInputWidth(input: any, focus?: boolean) {
    this.searchFocus = !!focus;
    if (!focus) {
      input.value = '';
    }

    if (!input.value) {
      this.searchFocusActive = !this.searchFocusActive;
      this.searchResults = [];
    }
  }

  search(term: string) {
    if (term) {
      this.searchResultsLoading = true;
      this.searchService.getResultSearch(term).subscribe((results: any) => {
        this.searchResults = results;
        this.searchResultsLoading = false;
      });
    }
  }

  btItemSearch_Click(item: C_SearchResult) {
    this.globalVars.SportSelected = '';
    if (item.SportHandle == 'greyhound_racing' || item.SportHandle == 'horse_racing') {
      const objHorsesAux: C_Horses = new C_Horses(
        item.Name,
        item.NodeId,
        item.ParentNodeId,
        item.EventNodeTypeId,
        item.Priority,
        item.SportHandle,
        item.ChildrenCount,
        item.Country,
        false,
        0,
        item.PaddockId,
        item.StartDate
      );
      this.navigate('HorsesMarket', { item: objHorsesAux });
    } else {
      if (item.isLive) {
        const objEventoAuxLive: C_EventLive = new C_EventLive(
          item.ResultInfo,
          '',
          item.SportHandle,
          '',
          0,
          '',
          true,
          item.Games,
          item.LeagueName,
          item.StartDate,
          item.isLive,
          item.StatisticsId,
          item.StreamingEnabled,
          item.Name,
          item.NodeId,
          item.ParentNodeId,
          item.EventNodeTypeId,
          item.Priority,
          item.SportHandle,
          item.ChildrenCount
        );
        this.navigate('NowLiveDetailPage', { state: { params: objEventoAuxLive } });
      } else {
        const objEventoAux: C_Event = new C_Event(
          item.Games,
          item.League,
          item.StartDate,
          item.isLive,
          item.StatisticsId,
          item.StreamingEnabled,
          item.Name,
          item.NodeId,
          item.ParentNodeId,
          item.EventNodeTypeId,
          // TODO item.Priority,
          item.SportHandle,
          item.ChildrenCount
        );
        this.navigate('MercadosPage', { state: { params: objEventoAux } });
      }
    }
  }

  changed(ev: any) {
    this.searchResultsLoading = true;
    this.searchService.inputWidth = this.searchInput.nativeElement.getBoundingClientRect().right - 400 + 'px';
    this.searchService.searchTerm.next(ev);
  }

  getSessionTime(): Date {
    const now: Date = new Date();
    const init: Date = this.globalVars.sessionInitDate;
    const sessionTimeResult = now.getTime() - init?.getTime();
    return new Date(sessionTimeResult);
  }

  async setTimeCol(): Promise<string | void> {
    if (this.globalVars.user?.logged && this.globalVars.sessionInitDate != null) {
      // Funcionalidad del contador de tiempo en CO y AR
      const sessionTime: Date = this.getSessionTime();

      const hora = sessionTime.getUTCHours();
      const minutos = sessionTime.getMinutes();
      const minutosStr = minutos < 10 ? '0' + minutos.toString() : minutos.toString();
      const segundos =
        sessionTime.getSeconds() < 10 ? '0' + sessionTime.getSeconds().toString() : sessionTime.getSeconds().toString();

      // Aviso de tiempo de juego en AR
      // si isTesting 1min, normalmente 1h
      const usedTime: number = this.isTesting ? minutos : hora;

      if (!this.isAlertAr && this.globalVars.licenseType == 33 && usedTime > this.timeAlertAr) {
        this.isAlertAr = true;
        this.timeAlertAr = this.isTesting ? minutos : hora;

        const alert = await this.alertController.create({
          backdropDismiss: false,
          cssClass: 'alertAr',
          message: `
                <img src="assets/global/img/argentina/timealert.gif"
                alt="Tiempo jugado">`,
          buttons: [{ text: '10' }]
        });
        alert.present().then(() => {
          let i = 10;
          const btn: Element = alert.querySelector('.alert-button');
          const int1 = setInterval(() => {
            if (btn) {
              btn.innerHTML = '0' + --i;
            }
            if (i == 0) {
              clearInterval(int1);
              this.isAlertAr = false;
              // for testing
              this.timeAlertAr = this.isTesting ? this.getSessionTime().getMinutes() : this.getSessionTime().getHours();
              alert.dismiss();
            }
          }, 1000);
        });
      }
      return hora.toString() + ':' + minutosStr + ':' + segundos;
    }
  }

  get showSearchInput() {
    return (
      (!this.globalVars.rootScope.registerPages.some((page) => this.router.url.includes(page)) &&
        this.router.url !== 'LobbyPage') ||
      this.router.url.includes('RegistroNewPage')
    );
  }

  get isRegistro() {
    return this.globalVars.rootScope.registerPages.some((page) => this.router.url.includes(page));
  }

  balancePopoverAvailable(): boolean {
    const allLobbyCasinoPages = [
      CASINO_PATHS.SlotsPage,
      CASINO_PATHS.OmnichannelPage,
      CASINO_PATHS.AviatorPage,
      CASINO_PATHS.VIPPage,
      CASINO_PATHS.CasinoEnVivoPage
    ];
    const single = 'singleGame=true';
    const isSingle = this.router.url.includes(single);
    if (this.globalVars.LICENSE_TYPE === LicenseTypes.Panama) {
      return this.balancesService.inCasinoPanama() && !isSingle && this.globalVars.FEATURES.balanceCasinoDetails;
    } else {
      return (
        !allLobbyCasinoPages.some((element) => this.router.url.includes(element)) &&
        !isSingle &&
        this.globalVars.FEATURES.balanceCasinoDetails
      );
    }
  }

  changeLogo() {
    this.userServiceMSO
      .getLogoHeader()
      .pipe(take(1))
      .subscribe((data) => {
        const datenow = new Date();
        const dateend = new Date(data.dateEnd);
        const datestar = new Date(data.dateStart);
        if (datenow >= datestar && datenow <= dateend) {
          this.headerImg = {
            imgClass: {
              common: data.classImgSpecialDesktop,
              sport: data.classImgSpecialDesktopSport,
              casino: data.classImgSpecialDesktopCasino
            },
            imgSrc: data.specialImg,
            imgSrcCas: data.specialImgCas,
            imgSrcSport: data.specialImgSport
          };
        } else {
          this.headerImg = {
            imgClass: {
              common: data.classImgDesktop,
              sport: data.classImgSportDesktop,
              casino: data.classImgCasinoDesktop
            },
            imgSrc: data.commonImg,
            imgSrcCas: data.commonImgCas,
            imgSrcSport: data.commonImgSport
          };
        }
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      });
  }
  //#region
  // ourSalas() {
  //   const url = 'https://bingos.codere.com.ar/';
  //   if (this.globalVars.isNative) {
  //     const options: InAppBrowserOptions = {
  //       location: 'yes',
  //       hidden: 'no',
  //       closebuttoncaption: 'Cerrar',
  //       closebuttoncolor: '#ffffff',
  //       footer: 'no',
  //       footercolor: '#000000',
  //       hardwareback: 'yes',
  //       hidenavigationbuttons: 'no',
  //       hideurlbar: 'yes',
  //       navigationbuttoncolor: '#ffffff',
  //       toolbarcolor: '#000000',
  //       zoom: 'yes',
  //       mediaPlaybackRequiresUserAction: 'no',
  //       shouldPauseOnSuspend: 'yes',
  //       useWideViewPort: 'yes',
  //       toolbar: 'yes',
  //       toolbartranslucent: 'yes',
  //       enableViewportScale: 'yes',
  //       allowInlineMediaPlayback: 'yes',
  //       keyboardDisplayRequiresUserAction: 'no',
  //       suppressesIncrementalRendering: 'no',
  //       presentationstyle: 'fullscreen', //pagesheet, formsheet, fullscreen
  //       transitionstyle: 'coververtical', //fliphorizontal, crossdissolve, coververtical
  //       toolbarposition: 'bottom', //top, bottom
  //       hidespinner: 'no'
  //     };
  //     // this.iab.create(encodeURI(url), '_blank', options);
  //   } else {
  //     window.open(url, '_blank');
  //   }
  // }
  //#endregion

  public async ourSalas() {
    const url = 'https://bingos.codere.com.ar/';

    if (this.globalVars.isNative) {
      const options = {
        toolbarColor: '#000000',
        windowColor: '#000000',
        backButtonCanClose: true,
        backButtonStyle: 'close',
        navigationBarColor: '#000000',
        showTitle: true
      };

      try {
        await Browser.open({ url: encodeURI(url), ...options });
      } catch (error) {
        error;
      }
    } else {
      window.open(url, '_blank');
    }
  }

  getUrl(): string {
    return this.router.url;
  }
}
