import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges,
  ViewChildren,
  ViewChild,
  QueryList,
  inject,
  CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertController, NavController } from '@ionic/angular';

// Models
import { MarqueeItem } from '@models/C_MarqueeItem';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';

// Pipes
import { TruncateStringPipe } from '@pipes/truncateString.pipe';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';

// Services

// Components
import { SbButtonComponent } from '@components/sports/sb-button/sb-button';
import { SbOverviewScoreSetsComponent } from '@components/sports/sb-overview-score-sets/sb-overview-score-sets';

// Other
import { Swiper, SwiperOptions } from 'swiper';
import { TrackingService } from '@providers/TrackingService';
import { DeviceService } from '@services/device.service';
import { NewTicketFacade } from '@models/sports/store/facades/ticket.facade';
import { I_BetSenseModel } from '../../models/I_BetSense.model';

// Components

@Component({
  selector: 'sb-marquee-betsense',
  templateUrl: './sb-marquee-betsense.html',
  standalone: true,
  imports: [CommonModule, SbButtonComponent, TruncateStringPipe, ExtractDatePipe, SbOverviewScoreSetsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ['./sb-marquee-betsense.scss']
})
export class SbMarqueeBetSenseComponent implements OnChanges, AfterViewInit {
  @ViewChildren('list') list: QueryList<any>;
  @Input() betSenseData: any[];
  @Input() switch: boolean;
  @Output() resizeEl: EventEmitter<any> = new EventEmitter();
  @Output() bet: EventEmitter<any> = new EventEmitter();
  betsenseMarquees: any[] = [];

  @ViewChild('betSenseSwiper') swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;
  public swiper?: Swiper;
  config: SwiperOptions = {
    loop: false,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next-betsense',
      prevEl: '.swiper-button-prev-betsense'
    },
    observer: true
  };
  globalVars!: GlobalVarsModel;

  _element = inject(ElementRef);
  _renderer = inject(Renderer2);
  extractDate = inject(ExtractDatePipe);
  nav = inject(NavController);
  alertCtrl = inject(AlertController);

  isMobile: boolean;
  isDesktop: boolean;

  trackingService = inject(TrackingService);
  deviceService = inject(DeviceService);

  newTicketFacade = inject(NewTicketFacade);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnChanges() {
    if (this.betSenseData.length > 0) {
      this.betsenseMarquees = this.betSenseData;
      // this.betsenseMarquees = this.updateMarquee(this.betsenseMarquees, this.betSenseData);
    }
    if (!this.swiper) return;
    this.swiper.update();
  }

  ngAfterViewInit() {
    if (this.swiperRef) {
      const swiperEl = Object.assign(this.swiperRef?.nativeElement, this.config);
      swiperEl.initialize();
      this.swiper = this.swiperRef?.nativeElement.swiper;
    }
  }

  updateMarquee(previousMarqueeData: MarqueeItem[], nextMarqueeData: MarqueeItem[]): MarqueeItem[] {
    if (previousMarqueeData.length === 0) return nextMarqueeData;

    return nextMarqueeData.map((marqueeItem) => {
      if (!marqueeItem.Game) return marqueeItem;

      const previousMarqueeItem = previousMarqueeData.find(
        (prevItem) => prevItem.Game?.NodeId === marqueeItem.Game.NodeId
      );

      if (!previousMarqueeItem) return marqueeItem;

      const updatedResults = marqueeItem.Game.Results.map((result) => {
        const previousResult = previousMarqueeItem.Game.Results.find(
          (prevResult) => prevResult.NodeId === result.NodeId
        );

        return {
          ...result,
          upOdd: previousResult ? previousResult.Odd < result.Odd : false,
          downOdd: previousResult ? previousResult.Odd > result.Odd : false
        };
      });

      return {
        ...marqueeItem,
        Game: {
          ...marqueeItem.Game,
          Results: updatedResults
        }
      };
    });
  }

  setBetsenseMarqueeItems(betSenseData) {
    const betsenseItems: I_BetSenseModel[] = [];
    betSenseData.forEach((betSenseDataItem) => {
      const NodeId = betSenseDataItem.outcomes.find(
        (item) => item.label === betSenseDataItem.delivery.es.log.cta_target
      ).id;
      const odd = betSenseDataItem.data.offer.outcomes.find((i) => i.id == NodeId).oddsDecimal;
      betsenseItems.push({
        title: betSenseDataItem.delivery?.es?.log?.body,
        Name: betSenseDataItem.delivery?.es?.log?.cta,
        Odd: odd,
        marketName: betSenseDataItem.data?.offer?.name,
        NodeId
      });
    });
    return betsenseItems.filter((item, index, self) => index === self.findIndex((t) => t.NodeId === item.NodeId));
  }

  onClick(NodeId) {
    this.bet.emit(NodeId);
  }
}
