import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { NewBaseService } from '@providers/newBase.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { PragmaticInfo, GameResult } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class PragmaticService {
  private casinoId: string;
  private serverId: string;
  private isSubscribed: boolean = false;
  private isConnected: boolean = false;
  _globals!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService?.getVars?.subscribe((data: GlobalVarsModel) => {
      this._globals = data;
      this.casinoId = this._globals?.casinoPragmaticId;
      this.serverId = this._globals?.casinoPragmaticServerId;
    });
  }

  private connectToServer(tableId: string): Promise<any> {
    return new Promise((success) => {
      if (this.isApiLoaded() && !this.isConnected) {
        window['dga'].connect(this.serverId);

        window['dga'].onMessage = (data) => {
          if (!data || !data.tableKey) {
            return;
          }
          if (data.tableKey != null) {
            for (const key in data.tableKey) {
              if (data.tableKey[key] === tableId) {
                this.subscribeToTable(tableId);
                success(true);
              }
            }
          }
        };

        window['dga'].onConnect = () => {
          window['dga'].available(this.casinoId);
          this.isConnected = true;
        };
      } else {
        success(true);
      }
    });
  }

  private subscribeToTable(tableId: string) {
    if (this.isApiLoaded() && !this.isSubscribed) {
      window['dga'].subscribe(this.casinoId, tableId, 'EUR');
      this.isSubscribed = true;
    }
  }

  getMessages(tableId: string): Observable<any> {
    return new Observable((observer) => {
      from(this.connectToServer(tableId)).subscribe({
        next: () => {
          if (this.isApiLoaded()) {
            window['dga'].onMessage = (data) => {
              if (data && data.tableId === tableId) {
                const pragmaticInfo: PragmaticInfo = {
                  totalSeatedPlayers: data.totalSeatedPlayers,
                  lastGameResults: this.getLastFiveResults(data.gameResult)
                };
                observer.next(pragmaticInfo);
              }
            };
          } else {
            observer.error('API no cargada');
          }
        },
        error: (err) => {
          observer.error(err);
        }
      });
    });
  }

  private isApiLoaded(): boolean {
    return typeof window['dga'] !== 'undefined';
  }

  private getLastFiveResults(gameResult: GameResult[]): number[] {
    return gameResult
      .slice(-5)
      .reverse()
      .map((gameResult) => Number(gameResult.result));
  }
}
