/* eslint-disable */
import { NavController, NavParams, IonicModule, ModalController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, HostListener, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { UserService } from '../../../providers/UserService';
import { UserServiceMSO } from '../../../providers/UserServiceMSO';
import { CashierPage } from '../../payments/cashier/cashier';
import { LicenseTypes } from '../../../models/MasterData';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { Utils } from '../../../utils/Utils';
import { EventsService } from '../../../providers/EventsService';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SlotsService } from '../../../providers/SlotsService';
import { DeviceService } from '../../../services/device.service';
import { PAYMENTS_PATHS } from '@shared-constants/routes';
import { TrackingService } from '@providers/TrackingService';
import { CasinoService } from '@services/casino/casino.service';
import { EventTypes } from '@models/TrackingEvents';
import { ParseMoney } from '@pipes/parseMoney';
import { DirectivesModule } from '@directives/directives.module';

/*To -> Models */
class RestrictionTimeOption {
  constructor(
    public label: string,
    public value: number
  ) {}
}

class SessionTimeOption {
  constructor(
    public label: string,
    public value: number
  ) {}
}

class SlotSession {
  constructor(
    public playerName: string,
    public providerId: any,
    public gameId: any,
    public amountLimit: number,
    public timeLimit: any,
    public adviceIn: any,
    public sessionAccessIn: any,
    public mobile: boolean
  ) {}
}

@Component({
  selector: 'slots-config-page',
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, TranslateModule, ParseMoney, DirectivesModule, FormsModule],
  providers: [DecimalPipe],
  templateUrl: './slots-config.html',
  styleUrls: ['./slots-config.scss']
})
export class SlotsConfigPage implements OnInit {
  slotsConfigForm: FormGroup;
  sessionTimeOptions: SessionTimeOption[];
  activityOptions: number[];
  restrictionTimeOptions: RestrictionTimeOption[];
  amount: number = 1;
  myWindow: any;
  selectedItem: any;
  typeGame: any;
  footerLastState: boolean;
  amountFocus: boolean;
  stepIncrement: number;
  minimumAmount;
  maxStart;
  isHabaneroProvider = false;
  maxAmountColombia = 1500000;
  maxAmountArg = 1500000;
  clickSend: boolean = false;
  placeholder;
  globalVars!: GlobalVarsModel;
  @ViewChild('inputFocus') private inputAmount: ElementRef;
  scrHeight;
  limitsResponse;
  isDesktop = false;
  isMobile = false;
  selectedRestrictionTime: number;
  totalBalance: number;
  totalBalanceString: string;
  fiftyPercentBalance: string;
  seventyFivePercentBalance: string;
  licenseTypes = LicenseTypes;
  lessThan60MinutesMessage: string;

  modalCtrl = inject(ModalController);
  translate = inject(TranslateService);
  extractDate = inject(ExtractDatePipe);
  fb = inject(FormBuilder);
  utils = inject(Utils);
  slotsService = inject(SlotsService);
  newBaseService = inject(NewBaseService);
  nav = inject(NavController);
  navParams = inject(NavParams);
  events = inject(EventsService);
  userService = inject(UserService);
  deviceService = inject(DeviceService);
  userServiceMSO = inject(UserServiceMSO);
  trackingService = inject(TrackingService);
  casinoService = inject(CasinoService);
  parseMoney = inject(ParseMoney);
  decimalPipe = inject(DecimalPipe);

  constructor() {
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.stepIncrement = Number(this.globalVars.FEATURES.LobbyAmountStepIncrement);
    this.minimumAmount = isNaN(this.getMinAmount()) ? Number(this.getMinAmount()) : this.getMinAmount();
    this.maxStart = isNaN(this.getMaxAmountStart()) ? Number(this.getMaxAmountStart()) : this.getMaxAmountStart();

    this.initValues();

    this.selectedItem = this.navParams.get('item');

    this.isHabaneroProvider = this.selectedItem.Name.substring(0, 3) === 'HAB' && this.globalVars.licenseType === 21;

    //typeGame: Slot/Casino
    this.typeGame = this.navParams.get('typeGame');
    this.placeholder = this.isSpain() ? '0' + this.globalVars.currencySymbol : this.globalVars.currencySymbol + '0';
    this.lessThan60MinutesMessage = this.navParams.get('lessThan60MinutesMessage');
    this.onResize();
  }

  ngOnInit(): void {
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      this.checkUserLimitsPa();
    }
    this.slotsConfigForm.get('restrictionTime').setValue(0);
    if (!this.isSpain()) {
      this.slotsConfigForm.get('amount').setValue(this.maxStart);
    }
    this.selectedRestrictionTime = this.restrictionTimeOptions[0]?.value;
    this.totalBalance = this.globalVars.user.balance;
    this.totalBalanceString = this.parseAmount(this.totalBalance, 2, true);
    if (this.globalVars.FEATURES.CAS_GameSessionImprovements) {
      this.fiftyPercentBalance = this.parseAmount(Math.round(this.totalBalance * 0.5), 0, true);
      this.seventyFivePercentBalance = this.parseAmount(Math.round(this.totalBalance * 0.75), 0, true);
      this.slotsConfigForm.get('activityTime').clearValidators();
      this.slotsConfigForm.get('restrictionTime').clearValidators();
    }
  }

  //Listener Height
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.scrHeight = window.innerHeight;
  }

  async ngAfterViewInit() {
    if (this.isSpain()) {
      this.amountFocus = false;
      setTimeout(
        () => {
          this.inputAmount.nativeElement.focus();
        },
        this.isDesktop ? 150 : 600
      );
    }
    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      setTimeout(
        () => {
          this.putMaxAmount();
          this.updateSessionTime(360);
          this.updateActivityTime(this.activityOptions[2]);
        },
        this.isDesktop ? 50 : 100
      );
    }
    if (this.utils.isArgentina() || this.utils.isPanama()) {
      setTimeout(
        () => {
          this.updateSessionTime(360);
          this.updateActivityTime(this.activityOptions[2]);
          this.changeAmount();
        },
        this.isDesktop ? 50 : 100
      );
    }
  }

  ionViewWillEnter() {
    this.footerLastState = this.globalVars.hideBetFooterBar;
    this.globalVars.hideBetFooterBar = true;
  }

  ionViewDidLeave() {
    this.globalVars.hideBetFooterBar = this.footerLastState;
  }

  initValues(): void {
    if (this.isSpain()) {
      this.sessionTimeOptions = [
        new SessionTimeOption(this.translate.instant('slot04h'), 4 * 60),
        new SessionTimeOption(this.translate.instant('slot02h'), 2 * 60),
        new SessionTimeOption(this.translate.instant('slot01h'), 1 * 60),
        new SessionTimeOption(this.translate.instant('slot30min'), 30)
      ];
      this.activityOptions = [60, 30, 15];
    } else {
      this.sessionTimeOptions = [
        new SessionTimeOption(this.translate.instant('slot01h'), 1 * 60),
        new SessionTimeOption(this.translate.instant('slot02h'), 2 * 60),
        new SessionTimeOption(this.translate.instant('slot04h'), 4 * 60),
        new SessionTimeOption('6 horas', 6 * 60)
      ];
      this.activityOptions = [15, 30, 60];
    }
    this.restrictionTimeOptions = [
      // new RestrictionTimeOption(this.translate.instant("SlotSessionNoLimits"), 0),
      new RestrictionTimeOption('Sin límite', 0),
      new RestrictionTimeOption(this.translate.instant('slotrestric10min'), 10),
      new RestrictionTimeOption(this.translate.instant('slotrestric20min'), 20),
      new RestrictionTimeOption(this.translate.instant('slotrestric30min'), 30),
      new RestrictionTimeOption(this.translate.instant('slotrestric40min'), 40),
      new RestrictionTimeOption(this.translate.instant('slotrestric50min'), 50),
      new RestrictionTimeOption(this.translate.instant('slotrestric60min'), 60)
    ];

    let timecodeid;

    if (this.utils.isArgentina() || this.utils.isPanama()) {
      timecodeid = this.globalVars.FEATURES.casinoTimeLimit;
    }

    this.slotsConfigForm = this.fb.group({
      sessionTime: [timecodeid, Validators.required],
      amount: ['', Validators.compose([Validators.required, Validators.min(this.minimumAmount)])],
      activityTime: ['', Validators.required],
      restrictionTime: [this.restrictionTimeOptions[0].value, this.isSpainOrColombia() ? Validators.required : null]
    });
  }

  getMinAmount() {
    return this.globalVars.FEATURES.LobbyMinimumAmount ? Number(this.globalVars.FEATURES.LobbyMinimumAmount) : 0;
  }

  getMaxAmountStart() {
    if (this.globalVars.licenseType === LicenseTypes.Colombia) {
      if (this.globalVars.user.balance > this.maxAmountColombia) {
        return this.maxAmountColombia;
      } else {
        return Math.trunc(this.globalVars.user.balance);
      }
    } else if (this.utils.isArgentina() || this.utils.isPanama()) {
      if (this.globalVars.user.balance > this.maxAmountArg) {
        return this.maxAmountArg;
      } else {
        return Math.trunc(this.globalVars.user.balance);
      }
    } else {
      return 0;
    }
  }

  isSpainOrColombia(): boolean {
    return (
      this.globalVars?.licenseType === LicenseTypes?.PaisVasco ||
      this.globalVars?.licenseType === LicenseTypes?.Nacional ||
      this.globalVars?.licenseType === LicenseTypes?.Madrid ||
      this.globalVars?.licenseType === LicenseTypes?.Colombia
    );
  }

  isSpain(): boolean {
    return (
      this.globalVars?.licenseType === LicenseTypes?.PaisVasco ||
      this.globalVars?.licenseType === LicenseTypes?.Nacional ||
      this.globalVars?.licenseType === LicenseTypes?.Madrid
    );
  }

  isColombia(): boolean {
    return this.globalVars?.licenseType === LicenseTypes?.Colombia;
  }

  isPanama(): boolean {
    return this.globalVars?.licenseType === LicenseTypes?.Panama;
  }

  isArgentina(): boolean {
    return (
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaCaba ||
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaCordoba ||
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaMendoza ||
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaPBA
    );
  }

  updateSessionTime(value: number) {
    this.slotsConfigForm.patchValue({ sessionTime: value });
    this.trackOption('sessionTime', value);
  }

  updateActivityTime(value: number) {
    if (this.slotsConfigForm.get('activityTime').value === value) {
      this.slotsConfigForm.patchValue({ activityTime: null });
    } else {
      this.slotsConfigForm.patchValue({ activityTime: value });
    }
    this.trackOption('activityTime', value);
  }

  async sendConfig() {
    this.clickSend = true;
    if (this.slotsConfigForm?.valid && this.checkAmount()) {
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        if (await this.checkRegulatoryPanama()) {
          this.sendForm(this.slotsConfigForm.value);
        }
      } else {
        this.sendForm(this.slotsConfigForm.value);
      }
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  public initGame(
    provider = this.selectedItem.ProviderId,
    gameId = this.selectedItem.GameId,
    gameType = null,
    occuranceId?: any,
    gameCategoryName = this.selectedItem.GameCategoryName,
    gameDyId = this.selectedItem.dyId,
    product = this.selectedItem.product,
    lobby = this.selectedItem.lobby
  ) {
    gameType = gameType || this.typeGame || 'slot';
    this.slotsService.initGame(
      provider,
      gameId,
      gameType,
      this.selectedItem.Name,
      this.modalCtrl,
      occuranceId,
      gameCategoryName,
      gameDyId,
      product,
      lobby,
      this.selectedItem.isIframe
    );
  }

  sendForm(form: any) {
    this.utils.loader();
    const session = new SlotSession(
      this.globalVars.user.username,
      this.selectedItem.ProviderId || this.selectedItem.game.ProvId,
      this.selectedItem.GameId || this.selectedItem.game.GameId,
      this.onlyNumbers(form.amount),
      form.sessionTime,
      form.activityTime,
      form.restrictionTime,
      this.isMobile
    );

    //time in ms
    this.globalVars.slotsAdviceIn = form.activityTime * 60 * 1000;

    this.slotsService.createSession(session).subscribe(
      (data) => {
        this.events.publish('loader:dismiss', null);
        this.utils.closeLoader();
        if (data != null) {
          if (data.CanPlay) {
            if (this.selectedItem.occurenceId) {
              this.initGame(
                this.selectedItem.game.ProvId,
                this.selectedItem.game.GameId,
                null,
                this.selectedItem.occurenceId
              );
            } else {
              this.initGame();
            }
            this.trackingService.track({
              eventType: EventTypes.GameSessionStarts,
              description: `Game Session started`,
              additionalData: {
                sessionData: session,
                lobby: this.selectedItem.lobby,
                game: session.gameId,
                platform: this.casinoService.getPlatform()
              }
            });
          } else {
            if (data.Reason == 'KO') {
              this.utils.showError('Error al crear sesión de slots');
            } else {
              this.utils.showError('Error al crear sesión de slots');
            }
            this.trackingService.track({
              eventType: EventTypes.GameSessionStarts,
              description: `Game Session error`,
              additionalData: {
                sessionData: session,
                lobby: this.selectedItem.lobby,
                game: session.gameId,
                platform: this.casinoService.getPlatform(),
                error: 'Error al crear sesión de slots'
              }
            });
          }
        } else {
          this.utils.showError('Error al crear sesión de slots');
          this.trackingService.track({
            eventType: EventTypes.GameSessionStarts,
            description: `Game Session error`,
            additionalData: {
              sessionData: session,
              lobby: this.selectedItem.lobby,
              game: session.gameId,
              platform: this.casinoService.getPlatform(),
              error: 'Error al crear sesión de slots'
            }
          });
        }
      },
      (error) => {
        this.events.publish('loader:dismiss', null);
        this.utils.closeLoader();
        this.utils.showError(this.translate.instant('slotsessionKO'));
        this.trackingService.track({
          eventType: EventTypes.GameSessionStarts,
          description: `Game Session error`,
          additionalData: {
            sessionData: session,
            lobby: this.selectedItem.lobby,
            game: session.gameId,
            platform: this.casinoService.getPlatform(),
            error: error
          }
        });
      }
    );
  }

  trackOption(optionName: string, value: any): void {
    this.trackingService.track({
      eventType: EventTypes.GameSessionOptionSelected,
      description: `Game Session option selected ${optionName} = ${value}`,
      additionalData: {
        optionData: {
          optionName: optionName,
          value: value
        },
        lobby: this.selectedItem.lobby,
        game: this.selectedItem.GameId || this.selectedItem.game.GameId,
        platform: this.casinoService.getPlatform()
      }
    });
  }

  toggleFocus(control = 'amount') {
    if ((control = 'amount')) {
      this.amountFocus = !this.amountFocus;
    }
  }

  async checkUserLimitsPa() {
    this.limitsResponse = await this.userServiceMSO.getLimits().toPromise();
  }

  async checkRegulatoryPanama() {
    let sesionLimit = {
      SessionLimitsGasto: Number(this.limitsResponse.SessionLimitsGasto),
      SessionLimitsTiempo: Number(this.limitsResponse.SessionLimitsTiempo)
    };
    if (
      sesionLimit.SessionLimitsGasto == 0 ||
      sesionLimit.SessionLimitsGasto == null ||
      sesionLimit.SessionLimitsGasto == undefined
    ) {
      sesionLimit.SessionLimitsGasto = Number(this.globalVars.FEATURES.casinoCostLimit);
    }
    if (
      sesionLimit.SessionLimitsTiempo == 0 ||
      sesionLimit.SessionLimitsTiempo == null ||
      sesionLimit.SessionLimitsTiempo == undefined
    ) {
      sesionLimit.SessionLimitsTiempo = Number(this.globalVars.FEATURES.casinoTimeLimit);
    }
    const form = { ...this.slotsConfigForm.value };
    form.amount = this.onlyNumbers(form.amount);
    if (
      this.globalVars.FEATURES.casinoCostLimitRegulator < form.amount ||
      sesionLimit.SessionLimitsGasto < form.amount ||
      sesionLimit.SessionLimitsTiempo < form.sessionTime ||
      this.globalVars.FEATURES.casinoTimeLimitRegulator < form.sessionTime
    ) {
      //Set data
      const dataSet = { amount: form.amount, sessionTime: form.sessionTime };
      if (
        this.globalVars.FEATURES.casinoCostLimitRegulator < form.amount ||
        sesionLimit.SessionLimitsGasto < form.amount
      ) {
        const setAmount =
          parseInt(this.globalVars.FEATURES.casinoCostLimitRegulator.toString()) > sesionLimit.SessionLimitsGasto
            ? sesionLimit.SessionLimitsGasto
            : this.globalVars.FEATURES.casinoCostLimitRegulator;
        dataSet.amount = setAmount;
      }
      if (
        sesionLimit.SessionLimitsTiempo < form.sessionTime ||
        this.globalVars.FEATURES.casinoTimeLimitRegulator < form.sessionTime
      ) {
        const setTime =
          this.globalVars.FEATURES.casinoTimeLimitRegulator > sesionLimit.SessionLimitsTiempo
            ? sesionLimit.SessionLimitsTiempo
            : this.globalVars.FEATURES.casinoTimeLimitRegulator;
        dataSet.sessionTime = setTime;
      }
      const msg =
        'Se han sobrepasado los límites impuestos para abrir la sesión de Slots,' +
        '<br> Se va a proceder a abrir la sesión con los límites más restrictivos';
      this.utils.alert(false, 'Regulación', msg, this.translate.instant('tAccept'), () => {
        this.putRegulatoryData(dataSet);
        this.sendForm(this.slotsConfigForm.value);
      });
      return false;
    } else {
      return true;
    }
  }

  putRegulatoryData(data) {
    this.slotsConfigForm.patchValue({
      amount: data.amount,
      sessionTime: parseInt(data.sessionTime)
    });
  }

  checkAmount() {
    const check = this.onlyNumbers(this.slotsConfigForm.get('amount').value);
    const saldo = this.onlyNumbers(this.totalBalanceString);
    if (check > saldo) {
      const msg = `Tu saldo actual es de: ${this.totalBalanceString}.  Introduce un límite de gasto inferior o haz un depósito`;

      this.utils.confirm(
        true,
        this.translate.instant('tFail'),
        msg,
        this.translate.instant('tCancel'),
        () => {
          this.putMaxAmount();
        },
        'Depositar',
        () => {
          this.modalCtrl.dismiss();
          setTimeout(() => {
            this.globalVars.rootScope.openModalOrPage(
              `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
              {},
              true,
              'modalCashier is-modal'
            );
          }, 500);
        }
      );
      return false;
    } else {
      return true;
    }
  }

  changeAmount(event?: any, addCurrency?: boolean) {
    const key = event?.key;
    const isNumberKey = key >= '0' && key <= '9';
    if (!event || isNumberKey || event.type === 'blur') {
      const amountInput = this.slotsConfigForm.get('amount');
      const nowVal = this.parseAmount(amountInput.value, 2, addCurrency);
      this.slotsConfigForm.patchValue({ amount: nowVal });
      this.trackOption('amount', nowVal);
    }
  }

  parseAmount(amount: string | number, decimals?: number, addCurrency?: boolean): string {
    const numericAmount = this.onlyNumbers(amount);
    const hasDecimals = numericAmount % 1 !== 0;
    const locale = this.initLocale();
    const digits = this.initDigits(hasDecimals, decimals, addCurrency);
    let nowVal = this.decimalPipe.transform(numericAmount, digits, locale) || '';
    if (addCurrency) {
      nowVal = this.addCurrency(nowVal);
    }
    return nowVal;
  }

  initLocale(): string {
    let locale: string;
    if (this.isColombia()) {
      locale = 'es-CO';
    } else if (this.isPanama()) {
      locale = 'es-PA';
    } else if (this.isArgentina()) {
      locale = 'es-AR';
    } else {
      locale = 'es';
    }
    return locale;
  }

  initDigits(hasDecimals: boolean, decimals?: number, addCurrency?: boolean): string {
    let digits: string;
    if (decimals && addCurrency && hasDecimals && !this.isArgentina()) {
      digits = `1.${decimals}-${decimals}`;
    } else if (decimals && hasDecimals && !this.isArgentina()) {
      digits = `1.0-${decimals}`;
    } else {
      digits = '1.0-0';
    }
    return digits;
  }

  addCurrency(amount): string {
    let nowValParsed = '';
    if (this.isSpain()) {
      nowValParsed = `${amount} ${this.globalVars.currencySymbol}`;
    } else {
      nowValParsed = `${this.globalVars.currencySymbol} ${amount}`;
    }
    return nowValParsed;
  }

  putMaxAmount() {
    if (!this.isSpain()) {
      this.slotsConfigForm.patchValue({ amount: this.maxStart.toString() });
      this.changeAmount();
    } else {
      this.slotsConfigForm.patchValue({ amount: '' });
      this.amountFocus = false;
      setTimeout(
        () => {
          this.inputAmount.nativeElement.focus();
        },
        this.isDesktop ? 150 : 800
      );
    }
  }

  starSessionDisabled() {
    if (this.isHabaneroProvider && this.globalVars.user.balance <= this.slotsConfigForm.get('amount').value) {
      return (
        !this.slotsConfigForm.get('sessionTime').valid ||
        !this.slotsConfigForm.get('activityTime').valid ||
        !this.slotsConfigForm.get('restrictionTime').valid
      );
    } else {
      return !this.slotsConfigForm.valid;
    }
  }

  onInputLimits(key) {
    const numero = Number(this.slotsConfigForm.get(key).value.replace(/[^0-9]/g, ''));
    const cadena = numero.toLocaleString('de-DE');
    this.slotsConfigForm.get(key).setValue(cadena);
  }

  onlyNumbers(item: string | number) {
    if (typeof item == 'number') {
      return item;
    }

    if (this.isSpainOrColombia() || this.isArgentina()) {
      item = item.replace(/\./g, '');
      item = item.replace(/,/g, '.');
    } else {
      item = item.replace(/,/g, '');
    }

    return parseFloat(item.replace(/[^0-9.]/g, ''));
  }

  updateBalance(amount: string): void {
    this.slotsConfigForm.patchValue({ amount: amount });
    this.trackOption('amount', amount);
  }

  compareWithBalance(amount: string): boolean {
    const nowVal = this.onlyNumbers(this.slotsConfigForm.get('amount').value);
    const numberAmount = this.onlyNumbers(amount);
    return numberAmount == nowVal;
  }

  openMoreInfo() {
    window.open(this.globalVars.FEATURES.Casino_URLResponsibleGaming, '_blank');
  }
}
