<ion-segment [scrollable]="scrollable" class="sb-tabs-segment" value="{{ selectedTab?.index }}" [ngClass]="color">
  <ion-segment-button
    class="sb-tabs-segment--button"
    [ngClass]="{ 'short-button': smallButton }"
    *ngFor="let cat of categories; let index = index"
    [value]="cat.index"
    [disabled]="cat?.disabled"
    (click)="onClick(cat, index)"
  >
    <ion-label
      class="sb-tabs-segment--label"
      [ngClass]="{ 'line-through': cat?.disabled, 'label-small-text': smallButton }"
      >{{ cat.name | translate | lowercase }}</ion-label
    >
  </ion-segment-button>
</ion-segment>
