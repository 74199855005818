import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';

// Service
import { SportService } from '@providers/SportService';

// Models
import { marketDataModel } from '../models/sportsbook.model';
import { C_Event, C_Horses, C_League, C_LeagueCategory, C_TodayLeagues, GlobalVarsModel } from '../../..';

// Stores
import { SportsbookState } from '../reducers/sportsbook.reducers';
import * as SportsbookActions from '../actions/sportsbook.actions';
import { SidebarFacade } from './sidebar.facade';
import { NewBaseService } from '@providers/newBase.service';
import { SportHandleList } from '@utils/sportHandleList';

@Injectable({ providedIn: 'root' })
export class SportsbookFacade {
  store = inject(Store<SportsbookState>);
  sportService = inject(SportService);
  newBaseService = inject(NewBaseService);

  sidebarFacade = inject(SidebarFacade);

  sportsbook$: Observable<any>;
  sportsbook: SportsbookState;

  selectedMarket: any;
  betsensePollingLaunched: boolean;
  selectedEvent: any;
  selectedRace: any;

  globals: GlobalVarsModel;

  constructor() {
    this.sportsbook$ = this.store.select((state) => state.sportsbook);
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globals = data;
          return of(null);
        })
      )
      .subscribe();
  }

  // Home Page
  initHomeData() {
    this.store.dispatch(SportsbookActions.initHome());
  }

  setHomeHighlighsSelectedSport(sport) {
    this.store.dispatch(SportsbookActions.setHomeHighlighsSelectedSport({ sport }));
  }

  stopHomePagePolling() {
    this.store.dispatch(SportsbookActions.stopHomePagePolling());
  }

  // Sport Countries Page
  getSportCountriesData(sport: C_League) {
    this.sidebarFacade.setSelectedSport(sport);
    this.store.dispatch(SportsbookActions.fetchSportCountries(sport));
  }

  // Today Page
  getTodayData() {
    this.store.dispatch(SportsbookActions.fetchTodayCategories());
  }

  fetchTodayLeagueEvents(league: C_TodayLeagues) {
    this.store.dispatch(SportsbookActions.fetchTodayLeagueEvents(league));
  }

  setTodaySelectedDay(selectedDay) {
    this.store.dispatch(SportsbookActions.setTodaySelectedDay({ selectedDay }));
  }

  setTodaySelectedSport(sport: C_LeagueCategory) {
    this.store.dispatch(SportsbookActions.setTodaySelectedSport(sport));
  }

  stopTodayPagePolling() {
    this.store.dispatch(SportsbookActions.stopTodayPagePolling());
  }

  // Event Page
  getEventData(league) {
    this.store.dispatch(SportsbookActions.fetchEventMainCategories(league));
  }

  setSelectedEventData(league) {
    this.selectedEvent = league;
    if (!this.selectedEvent?.SportName) this.selectedEvent.SportName = SportHandleList[`${league.SportHandle}Literal`];
  }

  setEventMarketSelectedCategory(eventMarketSelectedCategory) {
    this.store.dispatch(SportsbookActions.setEventMarketSelectedCategory(eventMarketSelectedCategory));
  }

  setEventMainSelectedCategory(eventMainSelectedCategory) {
    this.store.dispatch(SportsbookActions.setEventMainSelectedCategory(eventMainSelectedCategory));
  }

  stopEventPagePolling() {
    this.store.dispatch(SportsbookActions.stopEventPagePolling());
  }

  // Market Page
  setSelectedMarketData(market: C_Event) {
    this.selectedMarket = C_Event.parse(market);
  }

  getMarketData() {
    this.store.dispatch(SportsbookActions.fetchMarketCategories(this.selectedMarket));
  }

  setMarketSelectedCategory(category) {
    this.store.dispatch(SportsbookActions.setMarketSelectedCategory(category));
  }

  fetchMarketBetsense(market) {
    this.betsensePollingLaunched = true;
    this.store.dispatch(SportsbookActions.fetchMarketBetsense(market));
  }

  clearMarketData() {
    this.selectedMarket = null;
    this.store.dispatch(SportsbookActions.clearMarkets());
  }

  // Race Page
  fetchRace(raceType, endOfToday) {
    this.selectedRace =
      raceType === C_Horses.races.type.horse ? C_Horses.races.name.horse : C_Horses.races.name.greyhound;
    this.store.dispatch(SportsbookActions.fetchRace({ raceType, endOfToday }));
  }

  setRaceMarketSelectedRace(race) {
    this.store.dispatch(SportsbookActions.setRaceMarketSelectedRace(race));
  }

  stopRacePagePolling() {
    this.store.dispatch(SportsbookActions.stopRacePagePolling());
  }

  // Race Market Page
  initRaceMarket(params) {
    this.selectedRace =
      params.SportHandle === C_Horses.races.type.horse ? C_Horses.races.name.horse : C_Horses.races.name.greyhound;
    this.store.dispatch(SportsbookActions.initRaceMarket(params));
  }

  fetchRaceMarket(nextRacesParams, completeRaceParams) {
    this.selectedRace =
      nextRacesParams.SportHandle === C_Horses.races.type.horse
        ? C_Horses.races.name.horse
        : C_Horses.races.name.greyhound;
    this.store.dispatch(SportsbookActions.fetchRaceMarket({ nextRacesParams, completeRaceParams }));
  }

  stopRaceMarketPagePolling() {
    this.store.dispatch(SportsbookActions.stopRaceMarketPagePolling());
  }

  cleanErrorTrigger() {
    this.store.dispatch(SportsbookActions.setRaceMarket({ participants: [] }));
  }

  // Live Page
  initLive() {
    this.store.dispatch(SportsbookActions.initLive());
  }

  fetchLive(payload) {
    this.store.dispatch(SportsbookActions.fetchLive(payload));
  }

  setSelectedSportsNav(selectedSport) {
    this.store.dispatch(SportsbookActions.setSelectedSportsNav(selectedSport));
  }

  clearLive() {
    this.store.dispatch(SportsbookActions.clearLive());
  }

  stopLivePagePolling() {
    this.store.dispatch(SportsbookActions.stopLivePagePolling());
  }

  // Live Market Page
  initLiveMarket(payload) {
    this.store.dispatch(SportsbookActions.initLiveMarket(payload));
  }

  fetchLiveMarketBetsense(payload) {
    this.store.dispatch(SportsbookActions.fetchLiveMarketBetsense(payload));
  }

  setLiveMarketSelectedCategory(payload) {
    this.store.dispatch(SportsbookActions.setLiveMarketSelectedCategory(payload));
  }

  setLiveMarket(payload) {
    this.store.dispatch(SportsbookActions.setLiveMarket(payload));
  }

  clearLiveMarket() {
    this.store.dispatch(SportsbookActions.clearLiveMarket());
  }

  stopLiveMarketPagePolling() {
    this.store.dispatch(SportsbookActions.stopLiveMarketPagePolling());
  }

  stopAllStorePollings() {
    this.store.dispatch(SportsbookActions.stopAllStorePollings());
  }
}
