<!-- eslint-disable -->
<ng-container *ngIf="isDesktop">
  <ng-content></ng-content>
  <ng-content></ng-content>
</ng-container>

<span
  *ngIf="getNumberOfSelections(ticket) === 0 && ticket?.OveraskInfo?.Status == 'OK'"
  class="sb-ticket--empty-state color-grey-300 background-color-dark"
>
  {{ 'selectionsadded' | translate }}
</span>

<!-- Ticket resume header -->
<div
  class="sb-ticket--confirmation-header sb-ticket--header border-color-light background-color-dark"
  *ngIf="getTicketIsConfirmed(ticket)"
  [ngClass]="{ 'box-shadow': !accordionHidden }"
  tappable
  (click)="toggleConfirmation()"
>
  <div class="sb-ticket--confirmation-headings">
    <span *ngIf="!ticket?.MultisingleError" class="sb-ticket-selection--title color-light">
      {{ 'compapuestaT1' | translate }} {{ 'compapuestaT2' | translate }}
    </span>
    <span *ngIf="ticket?.MultisingleError" class="color-light"> {{ ticket?.MultisingleError }} </span>
    <span
      *ngIf="getTicketIsConfirmed(ticket) && ticket?.TicketNumber"
      class="sb-ticket-selection--subtitle color-grey-500"
    >
      {{'compapuestaP3' | translate}} #{{ ticket?.TicketNumber }}
    </span>
  </div>
  <i class="codere-icon color-light icon-arrow-bottom" [ngClass]="{ 'rotate-icon-180' : !accordionHidden }"></i>
</div>
<!-- End Ticket resume header -->

<!-- Overask START -->
<!-- toggleConfirmation does not recieve any arguments -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 0"
  class="sb-ticket--confirmation-header sb-ticket--header border-color-light background-color-dark"
  tappable
  (click)="toggleConfirmation()"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--title color-light"> Aprobación Requerida </span>
    <span class="sb-ticket-selection--subtitle color-grey-500"> Por favor espera </span>
  </div>
  <i
    *ngIf="isDesktop"
    class="codere-icon color-light icon-arrow-bottom"
    [ngClass]="{ 'rotate-icon-180' : !accordionHidden }"
  ></i>
</div>

<!-- Overask Status 1 START -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 1"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, TU APUESTA HA ALCANZADO EL LÝMITE PERMITIDO. APUESTA MAXIMA POSIBLE:
      <!-- added +"" to convert the value to string -->
      <span class="color-primary">{{ ticket?.OveraskInfo?.MaxStake+"" | parseStakeMoney:[1]}}</span>
    </span>
  </div>
</div>
<!-- Overask Status 1 END -->

<!-- Overask Status 3 START -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 3"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, TU APUESTA HA ALCANZADO EL LÍMITE PERMITIDO Y NO HA SE PODIDO REALIZAR
    </span>
  </div>
</div>
<!-- Overask Status 3 END -->

<!-- Overask TIMEOUT START  -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 2"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, EL TIEMPO DE RESPUESTA SE HA AGOTADO. REALIZA DE NUEVO TU APUESTA
    </span>
  </div>
</div>
<!-- Overask TIMEOUT END -->

<!-- Overask END -->
<div
  class="sb-ticket--filter background-color-regular"
  [elementDisabled]="getElementDisabled()"
  *ngIf="!getTicketIsConfirmed(ticket) && getNumberOfSelections(ticket) > 0 && ticket?.OveraskInfo?.Status == 'OK'"
>
  <ticket-filter
    (filter)="changeBetType($event)"
    [ticket]="ticket"
    [filters]="filters"
    [selected]="filterSelected"
    [switch]="true"
    [elementDisabled]="getElementDisabled()"
    [keyboard]="keyboard$ | async"
  >
  </ticket-filter>
</div>

<div
  #selections
  class="sb-ticket--selections"
  [ngClass]="{ 'background-color-dark': getTicketIsConfirmed(ticket) || isDesktop, 'background-color-regular': !getTicketIsConfirmed(ticket) && !isDesktop, 'selection-max-height': getTicketIsConfirmed(ticket) && !isDesktop }"
  [elementDisabled]="getElementDisabled()"
  [hidden]="accordionHidden"
>
  <!-- Promo -->
  <ticket-bonus *ngIf="!getTicketIsConfirmed(ticket)"> </ticket-bonus>
  <!-- Promo Fin -->
  <p hidden>{{ ticket?.TotalLocal}}</p>

  <div id="ticket-selections" class="background-color-dark">
    <!-- SingleResult -->
    <div *ngFor="let lineItem of ticket?.LineItems; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-selection
        [lineItem]="lineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        [disabled]="disabled$ | async"
        (scrollTo)="scrollTo($event)"
        (delete)="removeSelection(lineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
        <!-- unselectLineItem does not take arguments -->
      </ticket-selection>
    </div>

    <!-- StraightMulticast -->
    <div *ngFor="let lineItem of ticket?.StraightMulticast; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-selection
        [lineItem]="lineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="!!getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        (scrollTo)="scrollTo($event)"
        (delete)="removeStraightMulticast(lineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
      </ticket-selection>
    </div>

    <!-- CombinationMulticast -->
    <div *ngFor="let lineItem of ticket?.CombinationMulticast; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-selection
        [lineItem]="lineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="!!getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        (scrollTo)="scrollTo($event)"
        (delete)="removeCombinationItem(lineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
      </ticket-selection>
    </div>

    <div *ngFor="let smartLineItem of ticket?.SmartLineItems; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-betbuilder
        [smartLineItem]="smartLineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="!!getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        [disabled]="disabled$ | async"
        (scrollTo)="scrollTo($event)"
        (deleteSmartMarket)="removeSmartMarketSelection(smartLineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
      </ticket-betbuilder>
    </div>
  </div>
  <!-- End selections -->

  <!-- End Ticket resume content -->
  <div *ngIf="!getTicketIsConfirmed(ticket)" class="sb-ticket--delete">
    <button
      class="sb-ticket--button-inline color-dark background-color-transparent border-color-transparent"
      tappable
      (click)="clearTicket()"
    >
      <span class="sb-ticket--button-title"> {{'v2_eliminarTodo' | translate}} </span>
      <i class="codere-icon icon-close color-light"></i>
    </button>
  </div>
</div>

<div
  *ngIf="!getTicketIsConfirmed(ticket) && getNumberOfSelections(ticket) > 0 && ticket?.OveraskInfo?.Status == 'OK'"
  class="sb-ticket--footer"
>
  <div *ngIf="ticket?.BetType === 2" class="sb-ticket--system">
    <codere-grid-filter
      [collapsed]="multiBetCollapsed"
      [bgColor]="'background-color-light'"
      [currentEvent]="systemSelected"
      [isTicket]="true"
      [isDesktop]="isDesktop"
      [blocked]="possibleSystems?.length < 1"
      (select)="toggleMultipleBetOptions($event)"
    >
      <ul class="sb-ticket-multiselection--list">
        <li
          class="sb-grid-filter--item sb-ticket-multiselection--title color-dark"
          *ngFor="let option of possibleSystems"
          tappable
          (click)="changeInMultipleBetOptions(option)"
        >
          {{ option?.text }}
        </li>
      </ul>
    </codere-grid-filter>
  </div>
  <div class="sb-ticket--actions background-color-dark border-color-light box-shadow">
    <div class="sb-ticket--row-toggle">
      <div *ngIf="!isDesktop" class="sb-ticket--toggle"></div>

      <div class="sb-ticket--toggle" [hidden]="hideFreebetToggle()">
        <span *ngIf="isDesktop" class="sb-ticket--toggle-title color-light"> {{'fb' | translate}} </span>
        <ion-toggle
          [ngClass]="{'sb-ticket--toggle-icon-opacity': disabled$ | async}"
          class="sb-ticket--toggle-icon"
          #freebetToggle
          (ionChange)="toggleFreebet()"
        >
        </ion-toggle>
        <span *ngIf="!isDesktop" class="sb-ticket--toggle-title color-light"> {{'fb' | translate}} </span>
      </div>

      <div *ngIf="!getTicketIsConfirmed(ticket) && isDesktop" class="sb-ticket--delete">
        <button
          #eliminar_todo
          class="sb-ticket--button-inline color-light background-color-transparent border-color-transparent"
          tappable
          (click)="clearTicket()"
        >
          <span class="sb-ticket--button-title"> {{'v2_eliminarTodo' | translate}} </span>
          <i class="codere-icon icon-close color-light"></i>
        </button>
      </div>
    </div>
    <div class="sb-ticket--row-toggle" *ngIf="totalOddsEnabled && ticket.BetType === 1">
      <div *ngIf="!isDesktop" class="sb-ticket--toggle"></div>

      <div
        style="
          background-color: rgb(121, 192, 0);
          color: white;
          font-size: calc(10px + 1px * var(--word-length));
          border: none;
          max-width: 30px;
          min-width: 30px;
          max-height: 30px;
          vertical-align: middle;
          text-align: center;
          padding: 7px 5px 5px 6px;
          min-height: 30px;
          border-radius: 50px;
          margin-right: 10px;
        "
        class="sb-ticket--button-stake color-dark background-color-muted border-color-grey-200"
      >
        {{this.numberOfSelections}}
      </div>
      <span style="color: white">Selecciones</span>
      <div style="color: white; font-size: 14px; margin-right: 10px" class="sb-ticket--delete">
        {{ 'hOdd' | translate }} {{ cuota | formatOdd }}
      </div>
    </div>

    <div class="sb-ticket--row-final">
      <!--Can Bet-->
      <ng-container *ngIf="!isDesktop">
        <button
          *ngIf="!callHasErrors(ticket) && !ticket?.hasChangesInOdds"
          class="sb-ticket--button-stake color-dark background-color-muted border-color-grey-200"
          [class.text-small]="ticket?.TotalLocal.length > 6"
          [attr.mode]="hasFreebet() ? 'locked' : null"
          [disabled]="disabled$ | async"
          (click)="toggleTotalLocal()"
          [elementDisabled]="getElementDisabled()"
          tappable
        >
          <span class="sb-ticket--button-title text-uppercase"> {{ ticket?.TotalLocal | parseStakeMoney:[1] }} </span>

          <span *ngIf="false" class="sb-ticket--button-title text-small">
            {{ 'total' | translate }}: {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
          </span>
        </button>
      </ng-container>

      <p hidden>{{ ticket?.TotalLocal}}</p>

      <!-- <sb-ticket-stake
        *ngIf="isDesktop && !callHasErrors(ticket) && !ticket?.hasChangesInOdds && !hasFBError()"
        [minBetValue]="minBetValue"
        [ticket]="ticket"
        [currency]="currencySign"
        [finalRow]="ticket?.Total"
        [disabled]="disabled$ | async"
        (update)="checkAndUpdateStake($event)"
      >
      </sb-ticket-stake> -->

      <sb-betslip-stake-input
        *ngIf="isDesktop && !callHasErrors(ticket) && !ticket?.hasChangesInOdds && !hasFBError()"
        [minBetValue]="minBetValue"
        [ticket]="ticket"
        [currency]="currencySign"
        [finalRow]="ticket?.Total"
        [disabled]="disabled$ | async"
        (update)="checkAndUpdateStake($event)"
      ></sb-betslip-stake-input>

      <button
        *ngIf="!callHasErrors(ticket) && !hasFBError() && !ticket?.hasChangesInOdds"
        class="sb-ticket--button-bet color-light"
        (click)="placeBet()"
        [ngClass]="betButton"
        tappable
        [disabled]="disabled$ | async"
      >
        <span class="sb-ticket--button-title text-uppercase"> {{ btnBetText }} </span>
        <span class="sb-ticket--button-title text-small">
          {{'hDetailsGan' | translate}}: {{ ticket?.TotalPotentialWin?.toString() | parseStakeMoney:[1]}}
        </span>
      </button>

      <button
        *ngIf="callHasErrors(ticket)"
        class="sb-ticket--button-stake has-errors color-dark background-color-muted border-color-grey-200"
        [attr.mode]="hasFreebet() ? 'locked' : null"
        [class.text-small]="ticket?.TotalLocal?.length > 6"
        [disabled]="getElementDisabled()"
      >
        <span class="sb-ticket--button-title text-uppercase"> &#45; </span>
      </button>

      <button
        *ngIf="callHasErrors(ticket)"
        class="sb-ticket--button-warning color-light"
        [ngClass]="betButton"
        (click)="acceptErrorAndContinue(errorTicket)"
      >
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span class="sb-ticket--button-title text-small"> {{ errorTicket | translate }} </span>
      </button>

      <button
        *ngIf="hasFBError()"
        class="sb-ticket--button-stake has-errors color-dark background-color-muted border-color-grey-200"
        [attr.mode]="hasFreebet() ? 'locked' : null"
        [class.text-small]="ticket?.TotalLocal?.length > 6"
      >
        <span class="sb-ticket--button-title text-uppercase"> &#45; </span>
      </button>

      <button *ngIf="hasFBError()" class="sb-ticket--button-warning color-light" [ngClass]="betButton">
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span class="sb-ticket--button-title text-small"> {{ errorFB | translate }} </span>
      </button>

      <!-- Odd Acceptance -->
      <button
        *ngIf="!callHasErrors(ticket) && ticket?.hasChangesInOdds"
        tappable
        (click)="presentOddAcceptance()"
        class="sb-ticket--button-settings color-dark background-color-muted border-color-grey-200"
      >
        <i class="codere-icon icon-preferencias color-dark"></i>
        <span class="sb-ticket--button-title text-small text-left"> {{ 'bettingOptions' | translate }} </span>
      </button>

      <button
        *ngIf="!callHasErrors(ticket) && ticket?.hasChangesInOdds"
        (click)="acceptErrorAndContinue(null)"
        tappable
        class="sb-ticket--button-bet color-primary background-color-transparent border-color-primary"
      >
        <span class="sb-ticket--button-title text-uppercase"> {{ 'tAcepOdd' | translate }} </span>
      </button>
    </div>
  </div>
</div>

<div
  class="sb-ticket--footer background-color-dark border-color-light"
  [hidden]="!getTicketIsConfirmed(ticket) && ticket?.OveraskInfo?.Status == 'OK'"
>
  <div class="sb-ticket--actions background-color-dark border-color-light box-shadow">
    <!-- Overask Status 0 // Aprobación Requerida HEADER START -->
    <div *ngIf="getTicketIsConfirmed(ticket) || +ticket?.OveraskInfo?.Status == 0" class="sb-ticket--row-confirmation">
      <div class="sb-ticket--confirmation-footer">
        <span class="sb-ticket-selection--title color-grey-200"> {{ 'uPPExtractTap' | translate }} </span>
        <span class="sb-ticket-selection--title color-grey-200"> {{ ticket?.TotalLocal | parseStakeMoney:[1] }} </span>
      </div>

      <div class="sb-ticket--confirmation-footer">
        <span class="sb-ticket-selection--title color-grey-200"> {{ 'hDetailsGan' | translate}}: </span>
        <span *ngIf="getTicketIsConfirmed(ticket)" class="sb-ticket-selection--title color-grey-200">
          {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
        </span>
        <span *ngIf="+ticket?.OveraskInfo?.Status == 0" class="sb-ticket-selection--title color-grey-200">
          {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
        </span>
      </div>
    </div>
    <!-- Overask Status 0 // Aprobación Requerida HEADER END -->
    <!-- Overask Status 1: // Apuesta Máxima Posible HEADER START -->
    <div *ngIf="+ticket?.OveraskInfo?.Status == 1" class="sb-ticket--row-confirmation">
      <div class="sb-ticket--confirmation-footer">
        <span class="sb-ticket-selection--title color-grey-200">
          La oferta expira en:
          <span class="color-primary"> {{ newTicketFacade.overaskCountdown$ | async }} segundos </span>
        </span>
      </div>
    </div>
    <!-- Overask Status 1: // Apuesta Máxima Posible HEADER END -->
    <!-- CONFIRMED TICKET BUTTONS START -->
    <div *ngIf="ticket?.OveraskInfo?.Status == 'OK'" class="sb-ticket--row-final">
      <button
        class="sb-ticket--button-selection color-dark background-color-muted border-color-grey-200"
        tappable
        (click)="keepSelections()"
      >
        <span class="sb-ticket--button-title"> {{ 'keepselections' | translate }} </span>
      </button>
      <button class="sb-ticket--button-bet color-light" [ngClass]="betButton" tappable (click)="clearTicket()">
        <span class="sb-ticket--button-title text-uppercase"> Ok </span>
      </button>
    </div>
    <!-- CONFIRMED TICKET BUTTONS END -->
    <!-- Overask Status 0 // Aprobación Requerida BUTTONS START -->
    <div *ngIf="+ticket?.OveraskInfo?.Status == 0" class="sb-ticket--row-final">
      <button
        class="sb-ticket--button-selection background-color-muted border-color-grey-200"
        tappable
        (click)="closeResume()"
      >
        <span class="sb-ticket--button-title text-uppercase color-dark"> Cancelar </span>
      </button>
      <button class="sb-ticket--button-bet background-color-dark border-color-dark">
        <span class="sb-ticket--button-title text-uppercase color-primary"> Espera por favor </span>
      </button>
    </div>
    <!-- Overask Status 0 // Aprobación Requerida BUTTONS END -->
    <!-- Overask Status 1: // Apuesta Máxima Posible BUTTONS START -->
    <div *ngIf="+ticket?.OveraskInfo?.Status == 1" class="sb-ticket--row-final">
      <button
        class="sb-ticket--button-selection background-color-muted border-color-grey-200"
        tappable
        (click)="closeResume()"
      >
        <span class="sb-ticket--button-title text-uppercase color-dark"> Cancelar </span>
      </button>
      <button
        class="sb-ticket--button-bet color-light"
        [ngClass]="betButton"
        tappable
        (click)="placeBet()"
        [disabled]="disabled$ | async"
      >
        <span class="sb-ticket--button-title text-uppercase"> {{ btnBetText }} </span>
        <span class="sb-ticket--button-title text-small">
          {{'hDetailsGan' | translate}}: {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
        </span>
      </button>
    </div>
    <!-- Overask Status 1: // Apuesta Máxima Posible BUTTONS START -->
    <!-- Overask Status 2 /3 // Rejected / Timeout BUTTONS START -->
    <div class="sb-ticket--row-final" *ngIf="+ticket?.OveraskInfo?.Status > 1">
      <button
        class="sb-ticket--button-selection color-dark background-color-muted border-color-grey-200"
        tappable
        (click)="keepSelections()"
      >
        <span class="sb-ticket--button-title"> {{ 'keepselections' | translate }} </span>
      </button>
      <button
        class="sb-ticket--button-bet color-light background-color-primary border-color-primary"
        tappable
        (click)="closeResume()"
      >
        <span class="sb-ticket--button-title text-uppercase"> Ok </span>
      </button>
    </div>
    <!-- Overask Status 2 /3 // Rejected / Timeout BUTTONS END -->
  </div>
</div>
<!-- Ticket resume footer -->

<lb-play-out [page]="'Sports'" [hide]="hideGames"></lb-play-out>
