import { SbStreamMarketComponent } from './sb-stream-market/sb-stream-market';
export { SbStreamMarketComponent } from './sb-stream-market/sb-stream-market';

import { SbTabsComponent } from './sb-tabs/sb-tabs.component';
export { SbTabsComponent } from './sb-tabs/sb-tabs.component';

import { SbMarqueeBetSenseComponent } from './sb-marquee-betsense/sb-marquee-betsense';
export { SbMarqueeBetSenseComponent } from './sb-marquee-betsense/sb-marquee-betsense';

import { SbIconBarComponent } from './sb-icon-bar/sb-icon-bar.component';
export { SbIconBarComponent } from './sb-icon-bar/sb-icon-bar.component';

import { SBBetslipStakeComponent } from './ticket/betslip-stake-input/betslip-stake-input.component';
export { SBBetslipStakeComponent } from './ticket/betslip-stake-input/betslip-stake-input.component';

export const Components: any[] = [
  SbStreamMarketComponent,
  SbTabsComponent,
  SbMarqueeBetSenseComponent,
  SbIconBarComponent,
  SBBetslipStakeComponent
];
