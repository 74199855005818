import { inject, Injectable } from '@angular/core';
import { BaseServiceExt } from '@providers/BaseServiceExt';
import { DeviceService } from '@services/device.service';
import { forkJoin, map, Observable } from 'rxjs';
import * as models from '@models/index';
import { SportsConstants } from '../utils/sports.constants';
import { C_Game } from '@models/C_Game';
import { orderBy } from '@utils/utils.functions';
import { C_Event } from '@models/C_Event';
import { C_EventLive } from '@models/C_EventLive';
import { C_GameGroupByName } from '@models/C_GameGroupByName';
import { C_Result } from '@models/C_Result';

@Injectable({ providedIn: 'root' })
export class SportCommonService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  isDesktop: boolean;
  isMobile: boolean;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  getGameResultsOrdered(game: C_Game): any {
    const g = C_Game.parse(game);
    g.Results = Object.assign([], orderBy(game.Results, 'SortOrder'));
    return g;
  }

  setLockedResults(ev: C_Event | C_EventLive) {
    ev.Games.forEach((game) => {
      if (!game) return;
      return game.Results.map((result) => (result.Locked = game.Locked || ev.Locked));
    });
  }

  groupMarkets(games: any[], league: string = '') {
    let marketGameType: any;
    const groupedMarkets: any = [];

    games.forEach((market, index) => {
      market.Results.forEach((r: any) => {
        r.Spov = market.Spov;
      });

      if (!marketGameType || marketGameType !== market.Name) {
        const newMarket = new C_GameGroupByName();
        newMarket.copyGame(market);
        newMarket.AllSpov = [market.Spov];
        newMarket.nameLeague = league;
        newMarket.Locked = market.Locked;
        groupedMarkets.push(newMarket);
        marketGameType = market.Name;
      } else {
        if (marketGameType === market.Name) {
          groupedMarkets[groupedMarkets.length - 1].GroupResults = [
            ...groupedMarkets[groupedMarkets.length - 1].GroupResults,
            ...market.Results
          ];
        }
      }
    });

    return groupedMarkets;
  }

  doAlternatives(markets: any[], grouped: any[]) {
    const alternatives = {
      handicaps: [],
      totals: []
    };

    markets.forEach((market) => {
      if (this.globalVars.gameTypes.alternativeTotals.indexOf(market.GameType) !== -1) {
        alternatives.totals.push(market);
      }

      if (this.globalVars.gameTypes.alternativeHandicaps.indexOf(market.GameType) !== -1) {
        alternatives.handicaps.push(market);
      }
    });

    const mostBalancedTotals = this.getMostBalancedMarkets(alternatives.totals);
    mostBalancedTotals.Priority += 1;
    const mostBalancedHandicap = this.getMostBalancedMarkets(alternatives.handicaps);
    mostBalancedHandicap.Priority += 1;

    grouped = grouped.map((market) => {
      switch (market.GameType) {
        case mostBalancedTotals.GameType:
          market.GroupResults = market.GroupResults.filter((result) => result.Spov !== mostBalancedTotals.Spov);
          if (!market.GroupResults.length) return;
          market.Name = market.Name.includes('Alternativo') ? market.Name : market.Name + ' Alternativo';
          market.Collapsed = true;
          break;
        case mostBalancedHandicap.GameType:
          market.GroupResults = market.GroupResults.filter((result) => result.Spov !== mostBalancedHandicap.Spov);
          if (!market.GroupResults.length) return;
          market.Name = market.Name.includes('Alternativo') ? market.Name : market.Name + ' Alternativo';
          market.Collapsed = true;
          break;
      }
      return market;
    });
    //eliminate undefineds @a.e.m
    const g = [];
    for (const i of grouped) {
      if (i != null) g.push(i);
    }
    let result: any[] = [];
    result = [...g];
    if (mostBalancedTotals.Name != '') result.push(C_GameGroupByName.parse(mostBalancedTotals));
    if (mostBalancedHandicap.Name != '') result.push(C_GameGroupByName.parse(mostBalancedHandicap));
    return result;
  }

  private getMostBalancedMarkets(markets: any[]) {
    let balanced: number = Number.MAX_SAFE_INTEGER;
    let iterator = -1;
    markets.forEach((market, index) => {
      if (!market.Results[1]) {
        market.Results[1] = { odd: 0 };
      }
      if (!market.Results[0]) {
        market.Results[0] = { odd: 0 };
      }
      const res = Math.abs(market.Results[0].Odd - market.Results[1].Odd);
      if (res < balanced) {
        balanced = res;
        iterator = index;
      }
    });
    if (!markets[iterator]) {
      return this.getMissingMarket();
    }
    return markets[iterator];
  }

  public getMissingMarket() {
    return {
      CategoryInfo: null,
      CategoryInfos: null,
      ChildrenCount: null,
      DisplayTypeName: '',
      EventNodeTypeId: null,
      GameType: null,
      Name: '',
      NodeId: '',
      ParentNodeId: '',
      Priority: null,
      Results: [this.declareNewResultMissing(), this.declareNewResultMissing()],
      SmartMarketAvailable: false,
      SportHandle: null,
      Spov: null
    };
  }

  public declareNewResultMissing(): C_Result {
    return new C_Result('-', null, null, null, null, null, null, null, null, null, null, null);
  }

  public getEventGameTypes(event) {
    return this.globalVars.gameTypes.getOne(
      event.SportHandle,
      event.CountryCode,
      event.isLive,
      event.ParentNodeId,
      event.SportHandle === 'basketball' ? event.Games.map((game: models.C_Game) => game.GameType) : []
    );
  }
}
