import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[codereOnlyNumbers]'
})
export class OnlyNumbersDirective {
  constructor(private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.control.control?.value;

    const formattedValue = initialValue.replace(/[^0-9.,]/g, '');

    if (initialValue !== formattedValue) {
      this.control.control?.setValue(formattedValue);
    }
  }
}
