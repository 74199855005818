/* eslint-disable */
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Renderer2,
  ViewChild,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, of, switchMap } from 'rxjs';

// services
import { TicketService } from '../../../providers/TicketService';
import { MobileBehaviorService } from '../../../services/sports/mobileBehavior.service';

// models
import { C_TicketApuestasNew, TicketMethods } from '../../../models/C_TicketApuestas';
import { ticketState } from '../../../models/sports/store/reducers/ticket.reducers';
import { NewTicketFacade } from '../../../models/sports/store/facades/ticket.facade';

// others
import { Store } from '@ngrx/store';

// pipes
import { FormatOdd } from '../../../pipes/formatOdd.pipe';

// components
import { TicketCollapsedComponent } from '../../../components/sports/ticket-collapsed/ticket-collapsed';
import { TicketComponent } from '../ticket/ticket';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { KeyBoardComponent } from '../../../components/common/keyboard/keyboard';
import { NewBaseService } from '../../../providers/newBase.service';

@Component({
  selector: 'sb-tm-small-footer',
  templateUrl: './ticket-mobile-small-footer.html',
  host: {
    class: 'sb-tm-small-footer background-color-dark',
    id: 'sb-tm-small-footer'
  },
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    FormatOdd,
    TicketCollapsedComponent,
    TicketComponent,
    KeyBoardComponent
  ],
  styleUrls: ['./ticket-mobile-small-footer.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TicketMobileSmallFooterComponent {
  @ViewChild('ticketCollapsed', { read: ElementRef }) ticketCollapsed: ElementRef;
  @ViewChild('ticketFull', { read: ElementRef }) ticketFull: ElementRef;
  @ViewChild('swiperPullBar', { read: ElementRef }) swiperPullBar: ElementRef;
  @ViewChild('smallFooter', { read: ElementRef }) smallFooter: ElementRef;
  @ViewChild('swipableArea', { read: ElementRef }) swipableArea: ElementRef;

  ticket: C_TicketApuestasNew;

  showSmallFooter: boolean = true;
  showTicketCollapsed: boolean = false;
  showTicketFull: boolean = false;
  newTicket: boolean = true;
  ticketState: ticketState;
  ticketFullModalIsOpen: boolean = false;
  ticketCollapsedIsOpen: boolean = false;
  smallFooterIsOpen: boolean = false;

  keyboardIsOpen: boolean = false;
  keyboard$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  disableElement$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  smallFooterHeight: number = 100;
  smallFooterSetDisplay: string = 'none';
  keyboardHeight: number = 191;
  confirmationHeaderHeight: number = 66;

  smallFooterOpacity: number = 1;
  swipableAreaStyleHeight: any;
  swipableAreaStyleTransition: string = '';

  OPEN_FULL_TICKET: string = 'open-full-ticket';
  confirmedTicket: boolean = false;
  toggleLaunched: boolean = false;

  currentNumberOfSelections: number = 0;
  numberOfSelections: number = 0;

  firstOpen: boolean = false;

  touchmoveListenFunc: Function;
  mousemoveListenFunc: Function;
  touchendListenFunc: Function;
  touchcancelListenFunc: Function;
  mouseupListenFunc: Function;
  touchmoveListenFuncFooter: Function;
  mousemoveListenFuncFooter: Function;

  lastTouch: number;
  difference: number = 0;
  direction: string;

  initialYCoordinate: number;
  initialYCoordinateFooter: number;
  initialHeight: number;

  public cuota: string = '-';

  newTicketFacade = inject(NewTicketFacade);
  ticketService = inject(TicketService);
  mobileBehavior = inject(MobileBehaviorService);
  store = inject(Store<ticketState>);
  renderer = inject(Renderer2);
  elementRef = inject(ElementRef);
  newBaseService = inject(NewBaseService);
  globalVars: GlobalVarsModel;

  constructor() {
    this.store.select('ticket').subscribe((ticketState: any) => {
      this.ticketState = ticketState;
      this.ticket = ticketState.ticket;
      this.numberOfSelections = TicketMethods.getNumberOfSelections(this.ticket);
      this.newBaseService.getVars
        .pipe(
          switchMap((data: GlobalVarsModel) => {
            this.globalVars = data;
            return of(null);
          })
        )
        .subscribe();
      this.cuota = TicketMethods.getAmountBet(this.ticket) + '';

      if (this.numberOfSelections == 0 && !this.allTicketsClosed()) {
        this.hideAllTickets();
      } else {
        if (this.confirmedTicket) {
          this.openSmallTicket(true);
        }
        if (this.numberOfSelections !== this.currentNumberOfSelections && !this.confirmedTicket) {
          if (this.allTicketsClosed() && this.currentNumberOfSelections == 0 && this.numberOfSelections == 1) {
            this.openTicket();
            this.firstOpen = true;
          }
          if (this.numberOfSelections > this.currentNumberOfSelections && this.numberOfSelections > 1) {
            if (!this.ticketFullModalIsOpen) this.openSmallTicket(true);
          }
          this.currentNumberOfSelections = this.numberOfSelections;
        }
      }

      this.confirmedTicket = TicketMethods.getIsConfirmed(this.ticket);
    });

    this.mobileBehavior.disableElement$.subscribe((value) => this.disableElement$.next(value));
    this.mobileBehavior.keyboard$.subscribe((value) => {
      this.keyboard$.next(value);
      this.keyboardIsOpen = value;
    });
  }

  ngAfterViewInit() {
    this.mobileBehavior.scrolling$.subscribe((scrolling) => {
      if (scrolling && !this.confirmedTicket) {
        this.setSmallFooterShow(true);
        this.setTicketCollapsedShow(false);
      }
    });

    // SWIPER
    const swipableArea = this.swipableArea ? this.swipableArea.nativeElement : null;
    const smallFooter = this.smallFooter.nativeElement;
    // Start
    if (swipableArea != undefined && swipableArea != null) {
      this.renderer.listen(swipableArea, 'touchstart', (ev: TouchEvent) => this.onTouchStart(ev));
      this.renderer.listen(swipableArea, 'mousedown', (ev: TouchEvent) => this.onTouchStart(ev));
    }

    this.renderer.listen(smallFooter, 'touchstart', (ev: TouchEvent) => this.onTouchSmallFoterStart(ev));
    this.renderer.listen(smallFooter, 'mousedown', (ev: TouchEvent) => this.onTouchSmallFoterStart(ev));

    // End
    if (swipableArea != undefined && swipableArea != null) {
      this.touchendListenFunc = this.renderer.listen(swipableArea, 'touchend', (ev: TouchEvent) => this.onTouchEnd(ev));
      this.touchcancelListenFunc = this.renderer.listen(swipableArea, 'touchcancel', (ev: TouchEvent) =>
        this.onTouchEnd(ev)
      );
      this.mouseupListenFunc = this.renderer.listen(swipableArea, 'mouseup', (ev: TouchEvent) => this.onTouchEnd(ev));
    }
  }

  /**
   * Applies a class depending on the ticket mode or if it is confirmed
   */
  @HostBinding('class')
  get setMode(): string {
    return this.confirmedTicket
      ? 'ticket-confirmed'
      : this.ticket.OveraskInfo.Status !== 'OK'
        ? 'ticket-confirmed'
        : this.mobileBehavior.getMode();
  }

  /**
   * hides Ticket Collapsed depending on the Ticket Mode
   * @returns boolean
   */
  hiddenTicketCollapsed(): string {
    if (this.ticketFullModalIsOpen) return 'none';
    return this.mobileBehavior.getMode() == 'open-full-ticket' ? 'none' : 'block';
  }

  /**
   * hides Ticket Collapsed depending on the Ticket Mode
   * @returns boolean
   */
  hiddenFullTicket(): string {
    if (this.ticketCollapsedIsOpen) return 'none';
    if (this.ticketFullModalIsOpen) return 'flex';
    // return this.mobileBehavior.getMode() == 'open-full-ticket' ? 'flex' : 'none';
  }

  /**
   * Toggles the ticket depending on number of selection
   */
  toggle() {
    const mode = this.mobileBehavior.getMode();
    if (mode == this.OPEN_FULL_TICKET) {
      this.openFullTicket(true);
    } else {
      this.openSmallTicket(false);
    }
  }

  /**
   * Toggles the ticket/footer on param
   * @param toggle boolean
   */
  toggleSmallFooter(toggle: boolean) {
    this.setSmallFooterShow(toggle);
    this.setTicketCollapsedShow(!toggle);
  }

  /**
   * Open full ticket
   * @param toggle boolean
   */
  toggleFullTicket(fullTicket: boolean) {
    this.openFullTicket(fullTicket);
  }

  /**
   * Shows or Hides the SmallFooter Element
   * @param show boolean
   */
  setSmallFooterShow(show: boolean) {
    if (!show) {
      this.smallFooterSetDisplay = 'none';
      this.smallFooterIsOpen = false;
    } else {
      this.smallFooterSetDisplay = '';
      if (this.smallFooter) this.smallFooter.nativeElement.style.opacity = 1;
      this.smallFooterIsOpen = true;
    }
  }

  /**
   * Shows or hides the ticketCollapsed Element
   * @param show boolean
   */
  setTicketCollapsedShow(show: boolean) {
    const swipableArea = this.swipableArea ? this.swipableArea.nativeElement : null;
    this.ticketCollapsedIsOpen = show;
    if (swipableArea != undefined && swipableArea != null) {
      if (!show) {
        this.swipableAreaStyleTransition = '.2s ease-out';
        this.swipableAreaStyleHeight = `${this.smallFooterHeight}px`;
      } else {
        this.swipableAreaStyleTransition = '.2s ease-out';
        this.swipableAreaStyleHeight = 'auto';
      }
      this.removeTicketMobileTransition(300);
    }
  }

  /**
   * Shows or hides the ticketFull Element
   * @param show boolean
   */
  setTicketFullShow(show: boolean) {
    this.ticketFullModalIsOpen = show;
    if (this.swipableArea != undefined && this.swipableArea != null) {
      if (show) {
        this.setTopHeight();
      }
    }
  }

  /**
   * Sets the TicketMobile Height for OPENED/CLOSED confirmed Ticket
   * @param confirmed boolean
   */
  setConfirmedTicket(confirmed: boolean) {
    if (!confirmed && this.confirmedTicket) this.toggleSmallFooter(true);

    if (!confirmed) {
      this.swipableAreaStyleTransition = '.2s ease-out';
      this.confirmedTicket = confirmed;
    } else {
      this.swipableAreaStyleTransition = '.2s ease-out';
      this.swipableAreaStyleHeight = 'auto !important';
      this.confirmedTicket = confirmed;
    }
  }

  /**
   * Removes the transitions style of the swipableArea (host)
   * @param timeout ms delay in number
   */
  removeTicketMobileTransition(timeout: number) {
    setTimeout(() => {
      this.swipableAreaStyleTransition = '';
    }, timeout);
  }

  // SWIPER
  /**
   * OnStartTouch Event triggers touchmove listeners
   * @param e TouchEvent
   */
  onTouchStart(e: TouchEvent) {
    if (this.ticketFullModalIsOpen) {
      this.touchmoveListenFunc = null;
      this.mousemoveListenFunc = null;
      return;
    }
    if (this.swipableArea != undefined && this.swipableArea != null) {
      const swipableArea = this.swipableArea.nativeElement;
      if (e && e.touches && e.touches.length > 0) {
        this.initialYCoordinate = e.touches[0].clientY;
        this.initialHeight = window.innerHeight - this.initialYCoordinate;
      }
      this.touchmoveListenFunc = this.renderer.listen(swipableArea, 'touchmove', (ev) => this.onTouchMove(ev));
      this.mousemoveListenFunc = this.renderer.listen(swipableArea, 'mousemove', (ev) => this.onTouchMove(ev));
    }
  }

  /**
   * Works on the opacity and display of smallFooter
   * and the height of swipableArea
   * depending on the height of the user's touch on the screen
   * @param e TouchEvent
   */
  onTouchMove(e: TouchEvent) {
    if (this.swipableArea != undefined && this.swipableArea != null) {
      if (!this.keyboardIsOpen && !this.confirmedTicket && this.mobileBehavior.getMode() !== this.OPEN_FULL_TICKET) {
        const ticketMobileHeight = this.mobileBehavior.getModeHeight(); // TicketMobile Height
        const minHeight = this.smallFooterHeight; // Min Small Footer Height
        const threshold = ticketMobileHeight - minHeight; // Max Height - min Height

        if (e && e.touches && e.touches.length > 0) {
          let height = window.innerHeight - e.touches[0].clientY; // Calculate Ticket Mobile Height
          let opacity = 1;
          let smallFooterFinalOpacity = 1;

          // TouchEnd needed Data //
          if (!this.lastTouch) this.lastTouch = height;
          this.difference += this.lastTouch - height; // Calculate Difference from Y coordinate and prev Coordinate
          // Sets the direction for the touchEnd listener
          this.direction = this.lastTouch < height ? 'up' : 'down';
          // Sets the last touch height
          this.lastTouch = height;
          // TouchEnd needed Data

          if (this.ticketCollapsedIsOpen) {
            let thresholdHeight = (height * 100) / this.initialHeight;
            let finalTicketMobileHeight = +(ticketMobileHeight * (thresholdHeight / 100)).toFixed(0);

            // Check if the Height transformation is between the min Ticket Height and the max ticket Height
            if (minHeight < finalTicketMobileHeight && finalTicketMobileHeight < ticketMobileHeight) {
              // Set Footer Opacity depending on the finalTicket threshold
              smallFooterFinalOpacity = -((finalTicketMobileHeight - minHeight) / threshold).toFixed(2) + 1;
              // Set the host min height
              this.swipableAreaStyleHeight = `${finalTicketMobileHeight}px`;
            } else {
              // Check if the handle is lower than the min Ticket Height
              if (finalTicketMobileHeight > ticketMobileHeight) smallFooterFinalOpacity = 0;
              // Check if the handle is higher than the max Ticket Height
              if (finalTicketMobileHeight < minHeight) smallFooterFinalOpacity = 1;
            }
          } else {
            if (minHeight < height && height < ticketMobileHeight) {
              // Calculates the opacity depending on the threshold
              smallFooterFinalOpacity = opacity - ((height - minHeight) * 100) / threshold / 100;
              // Set the host min height
              this.swipableAreaStyleHeight = `${height}px`;
            }
            // Check if the handle is lower than the min Ticket Height
            if (height > ticketMobileHeight) smallFooterFinalOpacity = 0;
          }

          // Sets the ticket Footer Opacity
          this.smallFooterOpacity = smallFooterFinalOpacity;

          // Removes the display: 'none' on small footer
          this.smallFooterSetDisplay = '';
          // Removes transition on host
          this.swipableAreaStyleTransition = '';

          if (e.cancelable) e.preventDefault();
          e.stopImmediatePropagation();
        }
      } else {
        if (!this.keyboardIsOpen && !this.confirmedTicket && this.numberOfSelections > 2 && !this.ticketFullModalIsOpen)
          this.openFullTicket(true);
      }
    }
  }

  /**
   * Listen to touchend event to pull up or down
   * triggering show or hide the ticket
   * @param e TouchEvent
   */
  onTouchEnd(e: Event) {
    if (this.swipableArea != undefined && this.swipableArea != null) {
      if (!this.keyboardIsOpen && !this.confirmedTicket && !this.ticketFullModalIsOpen) {
        if (this.difference > 10 && this.direction == 'down') this.setBottomHeight();
        if (this.difference < -10 && this.direction == 'up') this.setTopHeight();

        this.lastTouch = null;
        this.difference = 0;
        this.removePreviousTouchListeners();
      }
    }
  }

  /**
   * Hides the smallFooter Element and opens the Host Element
   * @param swipableArea swipableArea (host) Element
   * @param smallFooter small Footer Element
   */
  setTopHeight() {
    this.swipableAreaStyleTransition = '.2s ease-out';
    this.swipableAreaStyleHeight = 'auto';
    this.smallFooterOpacity = 0;
    this.smallFooterSetDisplay = 'none';

    this.removeTicketMobileTransition(300);

    this.smallFooterIsOpen = false;
    this.ticketCollapsedIsOpen = this.mobileBehavior.getMode() !== this.OPEN_FULL_TICKET;
    this.ticketFullModalIsOpen = this.mobileBehavior.getMode() == this.OPEN_FULL_TICKET;
  }

  /**
   * Show the small Footer Elements and hides the Host Element
   * @param swipableArea swipableArea (host) Element
   * @param smallFooter small Footer Element
   */
  setBottomHeight() {
    this.swipableAreaStyleTransition = '.2s ease-out';
    this.swipableAreaStyleHeight = `${this.smallFooterHeight}px`;
    this.smallFooterOpacity = 1;
    this.smallFooterSetDisplay = '';

    this.removeTicketMobileTransition(300);

    this.smallFooterIsOpen = true;
    this.ticketCollapsedIsOpen = false;
    this.ticketFullModalIsOpen = false;
  }

  /**
   * Removes the touch Listeners
   */
  removePreviousTouchListeners() {
    if (this.touchendListenFunc !== null) this.touchendListenFunc(); // remove previous listener
    if (this.touchcancelListenFunc !== null) this.touchcancelListenFunc(); // remove previous listener
    if (this.mouseupListenFunc !== null) this.mouseupListenFunc(); // remove previous listener
    if (this.touchmoveListenFunc !== null) this.touchmoveListenFunc(); // remove previous listener
    if (this.mousemoveListenFunc !== null) this.mousemoveListenFunc(); // remove previous listener

    this.touchendListenFunc = null;
    this.touchcancelListenFunc = null;
    this.mouseupListenFunc = null;
    this.touchmoveListenFunc = null;
    this.mousemoveListenFunc = null;
  }

  /**
   * OnStartTouch Event triggers touchmove listeners
   * @param e TouchEvent
   */
  onTouchSmallFoterStart(e: TouchEvent) {
    const smallFooter = this.smallFooter.nativeElement;
    if (e && e.touches && e.touches.length > 0) {
      this.initialYCoordinateFooter = e.touches[0].screenY;
    }
    this.touchmoveListenFuncFooter = this.renderer.listen(smallFooter, 'touchmove', (ev) =>
      this.onTouchSmallFoterMove(ev)
    );
    this.mousemoveListenFuncFooter = this.renderer.listen(smallFooter, 'mousemove', (ev) =>
      this.onTouchSmallFoterMove(ev)
    );
  }

  /**
   * OnMove Event applies small footer behavior
   * @param e TouchEvent
   */
  onTouchSmallFoterMove(e: TouchEvent) {
    if (e && e.touches && e.touches.length > 0) {
      let movingPosition = e.touches[0].screenY;
      if (this.initialYCoordinateFooter > movingPosition && !this.toggleLaunched) {
        this.toggle();
        this.toggleLaunched = true;
      }
    }
  }

  /**
   * Removes TouchMove Listeners
   * @param e TouchEvent
   */
  onTouchEndSmallFooter(e: TouchEvent) {
    if (this.touchmoveListenFuncFooter !== null) this.touchmoveListenFuncFooter(); // remove previous listener
    if (this.mousemoveListenFuncFooter !== null) this.mousemoveListenFuncFooter(); // remove previous listener

    this.touchmoveListenFuncFooter = null;
    this.mousemoveListenFuncFooter = null;
    this.toggleLaunched = false;
  }

  hideAllTickets() {
    this.setSmallFooterShow(false);
    this.setTicketCollapsedShow(false);
    this.setTicketFullShow(false);
    this.currentNumberOfSelections = 0;
    this.firstOpen = false;
  }

  ticketsClosed(): boolean {
    return this.ticketCollapsedIsOpen || this.ticketFullModalIsOpen || this.smallFooterIsOpen;
  }

  openTicket() {
    const mode = this.mobileBehavior.getMode();
    switch (mode) {
      case this.OPEN_FULL_TICKET:
        this.openFullTicket(true);
        break;
      default:
        this.openSmallTicket(this.mobileBehavior.getMode() == this.OPEN_FULL_TICKET);
        break;
    }
  }

  openFullTicket(open: boolean) {
    if (!open) {
      this.newTicketFacade.closeKeyboard();
    }
    this.setSmallFooterShow(!open);
    this.setTicketCollapsedShow(false);
    this.setTicketFullShow(open);
  }

  openSmallTicket(showSmallFooter: boolean) {
    this.setSmallFooterShow(showSmallFooter);
    this.setTicketCollapsedShow(!showSmallFooter);
    this.setTicketFullShow(false);
  }

  isSmallTicketOpen(): boolean {
    return this.smallFooterIsOpen;
  }

  isTicketCollapsedOpen(): boolean {
    return !this.ticketCollapsedIsOpen && this.mobileBehavior.getMode() !== this.OPEN_FULL_TICKET;
  }

  openKeyboardOnTicketFull(): boolean {
    if (this.ticketFullModalIsOpen) return this.ticketFullModalIsOpen && this.keyboardIsOpen;
    return !this.ticketCollapsedIsOpen && this.mobileBehavior.getMode() == this.OPEN_FULL_TICKET && this.keyboardIsOpen;
  }

  isTicketFullOpen(): boolean {
    return this.ticketFullModalIsOpen;
  }

  allTicketsClosed() {
    return !this.smallFooterIsOpen && !this.ticketCollapsedIsOpen && !this.ticketFullModalIsOpen;
  }

  public updateAmount(value: any) {
    this.newTicketFacade.updateAmountLocal(value);
  }

  closeKeyboard(e: any) {
    if (!e) {
      this.mobileBehavior.closeKeyboard(true);
    } else {
      const betAmount = this.checkMinBetAmount(e);
      this.newTicketFacade.okPressKeyboard(betAmount);
    }
  }

  public checkMinBetAmount(e: string) {
    let amount;
    if (this.ticket) {
      switch (this.ticket.BetType) {
        case 2: // Multi
          amount = this.setSystemAmountController(e);
          break;
        case 1: // Acumulator
        default: // Singles
          amount = this.setSingleAmountController(e);
          break;
      }
      return amount;
    }
  }

  setSingleAmountController(inputAmount: string) {
    const amount =
      +inputAmount > +this.globalVars.FEATURES.BetSlipLegMinValue
        ? inputAmount.toString()
        : this.globalVars.FEATURES.BetSlipLegMinValue;
    return amount;
  }

  setSystemAmountController(inputAmount: string) {
    const minValue = this.ticket.SystemSelected.numberOfBetWays * +this.globalVars.FEATURES.BetSlipLegMinValue;
    const amount = +inputAmount > minValue ? inputAmount.toString() : minValue.toString();
    return amount;
  }

  /**
   *
   * @param event Event
   * @returns hide/show small footer method
   */
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.clickInsideTicket(event.target);
  }

  /**
   * Is inside ticket?
   */

  clickInsideTicket(target) {
    let substrings: string[] = [
      'ticket',
      'keyboard',
      'swiper-pull-bar',
      'sb-tm-small-footer',
      'alert',
      'sb-marquee-betsense--button'
    ];
    const mode = this.mobileBehavior.getMode();

    if (
      substrings.some(
        (v) =>
          target.className.includes(v) ||
          target.id.includes(v) ||
          target.parentElement.id.includes(v) ||
          target.parentElement.className.includes(v) ||
          mode == this.OPEN_FULL_TICKET ||
          this.firstOpen
      )
    ) {
      console.log('CONTAINS');
      if (this.firstOpen) this.firstOpen = false;
      return;
    } else {
      this.openSmallTicket(true);
    }
    if (this.keyboard$.getValue()) this.newTicketFacade.closeKeyboard();
  }

  /**
   * Ticket Methods
   */
  isConfirmed = (): boolean => TicketMethods.getIsConfirmed(this.ticket);

  hasFreebet = (): boolean => TicketMethods.getHasFreebet(this.ticket);
}
