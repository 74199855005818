/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Platform, App, Events, NavController, Keyboard } from 'ionic-angular';
import { Platform, NavController } from '@ionic/angular';

import { Location as LocationS } from '@angular/common';
import { Component, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// import { HomePage, LobbyCasinoPage } from '../pages';

import { C_Event } from '../models/C_Event';
import { C_User } from '../models/C_User';
import { C_Features } from '../models/C_Features';
import { C_Banners } from '../models/C_banners';
import { C_Customization } from '../models/C_Customization';
import { C_GameTypes } from '../models/C_GameTypes';
import { LicenseTypes } from '../models/MasterData';
import { Bonus } from '../models/csbgonlinecasinobonuses/Bonus';
import { C_BetsData } from '../models/C_BetsData';
import { I_NewCarousel } from '../models/I_NewCarousel';
import { EventsService } from './EventsService';

//read globalsVars from JS ROOT
//RT-> Refresh time
//TK-> Ticket
interface GLOBALS {
  URLBASE_STREAMING: string;
  DOMAIN: string;
  URLBASE: string;
  URLBASEUAT: string;
  URLBASE_REPORTS: string;
  URLBASE_BANNERS: string;
  URLBASE_TRANSACTIONS_API: string;
  URLBASE_OPERATIONS_API: string;
  URLBASE_BALANCESERVICE_API: string;
  URLBASE_COMMON_API: string;
  DEBUG_MODE: boolean;
  DEVICE_TYPE: string;
  TK_RT: string;
  TK_DESK_RT: string;
  TK_CLOSE: string;
  LIVE_RT: string;
  HOME_RT: string;
  HIGHLIGHTS_RT: string;
  VERIDAS_POLLING_RT: string;
  LICENSE_TYPE: number;
  BROWSER: string;
  TRANSAPI_PointofSalePayment: boolean;
  TRANSAPI_BankAccountPayment: boolean;
  TRANSAPI_HalCashPayment: boolean;
  TRANSAPI_BgtCardPayment: boolean;
  PLATFORM: string;
  TRANSAPI_PointofSaleDeposit: boolean;
  TRANSAPI_ElectronicCardDeposit: boolean;
  TRANSAPI_TeleingreosDeposit: boolean;
  TRANSAPI_PaySafeCardDeposit: boolean;
  TRANSAPI_BgtCardDeposit: boolean;
  TRANSAPI_CRUDoverTransactions: boolean;
  XMLHttpRequest_WITHCREDENTIALS: boolean;
  EPGCREDITCARDENABLED: boolean;
  XTREME_PUSH_APPKEY: string;
  ANDROID_SENDER_ID: string;
  CUSTOM_INSIGHT_URI: string;
  APP_VERSION: string;
  LOBBY_IMAGES: string;
  PROVIDER_IMAGES: string;
  LOBBY_JACKPOTS: string;
  LOBBY_JACKPOTS_TICKERS: string;
  URLBASE_VERIDAS_API: string;
  URLBASE_CASINO_BONUSES: string;
  URLBASE_CODERE_EVENT_BUS: string;
  URLBASE_USERSERVICE: string;
  URLBASE_USERSERICE_STAND_ALONE: string;
  URLBASE_REGAR: string;
  URLBASE_REGPBA: string;
  URLBASE_REGMX: string;
  URLBASE_LOGINSERVICES: string;
  URLBASE_REGCONEW: string;
  JACKPOTSSERVICES: string;
  LIVEGAMEDATA: string;
  ROYALDEGREE: string;
  CODERE_FANTASY: string;
  CODERE_FANTASY_HP_URL: string;
  DYNAMICYIELD: string;
  RECENTGAMES: string;
  FREEBET_BONUS: string;
  COOKIES: {
    customOK: boolean;
    analyticsOK: boolean;
    advertismentOK: boolean;
  };
  URLBASE_MYBETS: string;
  CORDOVA: number;
  MAIN_PAGE: string;
  MAIN_COMPONENT: any;
  FEATURES: C_Features;
  BASEDOMAIN: string;
  NAVIGATIONSERVICEBASEURL: string;
  BETSLIPSERVICEBASEURL: string;
  PRODUCTION: boolean;
  HTTP_ERRORS_THAT_RESTART_APP: number[];
  ERROR_TRACKER_INTERCEPTOR_URLS_2_INSPECT: string[];
  HTTP_ERROR_DESCRIPTION: string;
  TIMEOUT_INTERCEPTOR_URLS_2_INSPECT: string[];
  ENABLE_TIMEOUT_FOR_BACKEND_CALLS: boolean;
  TIMEOUT_FOR_BACKEND_CALLS_IN_MSECS: number;
  TIMEOUT_ERROR_DESCRIPTION: string;
  ENABLE_TRACE_CALL_TIME: boolean;
  GET_TICKETS_URL: string;
  SPORTS_MISC: string;
  POINT_TO_SPORTS_MISC: boolean;
  GOOGLE_MAPS_URL: string;
  GOOGLE_MAPS_API_KEY: string;
  CASINO_PRAGMATIC_ID: string;
  CASINO_PRAGMATIC_SERVER_ID: string;
}

//eslint-disable-next-line
declare var GLOBALS: GLOBALS;

//WARNING! All the parameters set in ApplicationState must end with 'On'. Otherwise they will be ignored by trackEvent
class CApplicationState {
  MiniRuletaOn: boolean;
  StreamingOn: boolean;

  constructor() {
    this.MiniRuletaOn = false;
    this.StreamingOn = false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GlobalVars {
  UrlBase: string;
  UrlTransactionsApiBase!: string;
  UrlOperationsApiBase!: string;
  UrlVeridasApiBase: string;
  UrlCommonApiBase!: string;
  DomainBase: string;
  UrlBaseReports: string;
  urlBaseBanners: string;
  UrlBaseCasinoBonuses: string;
  UrlBaseCodereEventBus: string;
  UrlBaseBettingServiceHub: string;
  UrlRegMx: string;
  UrlRegAR: string;
  UrlRegPBA: string;
  UrlRegCONew: string;
  browser: string;
  //language
  Idioma: string;
  //checks if the tickets must be opened or not
  TicketOpened: boolean;
  //sport_handle active
  SportSelected: string;
  SportHandleSelected: string;
  LeagueSelected!: string;
  sportItemsId$: BehaviorSubject<any> = new BehaviorSubject(null);
  //amount to bet
  amountBet: number;

  //forces to hide the footerBar
  hideBetFooterBar: boolean;

  //odd type from: "dec" / "fra" / "ame"
  oddType: string;

  //user of CUser
  user: C_User;
  extraUserData: any;
  comeFromLogin: boolean;
  optinPopupOpen: boolean;

  //user isProhibited
  isProhibited = false;

  //main page app
  mainPageApp: string;
  mainPageComponent: any;

  //fiveLoginOK AR
  fiveLoginOK: boolean = false;
  //the string Name of activePage
  currentPage: any;

  // Page to go to after Registration
  afterRegisterGoBackToPage: any;
  onRegisterProductType!: string | null;

  //all the pages
  pages!: Array<tPage>;
  minBetValue: number;
  currencySymbol: string = '€';
  currencyISOCode: string = '';
  currencyLiteral!: string;

  //when the user is on mobile or not
  isMobile: boolean;
  isDesktop: boolean;
  isNative: boolean;
  devicePlatform: string;

  //device type : web // android // ios <- indica si es web o Nativas
  deviceType: string;

  //device model : android // ios <- indica el modelo de dispositivo
  deviceModel!: string;

  //Native device's operating system name: // Depending on the device, a few examples are:- "Android"- "browser"- "iOS"- "WinCE"- "Mac OS X" --> indica la plataforma (Device.platform for IonicNativePlugin)
  nativeDevice: any;

  //the window identifier of slots game
  slotWindow: any;

  //is the user geolocalized?
  isGeolocalized: boolean;
  //derived from geolocalization
  isCaba!: boolean; //Argentina specific, if the user is in Ciudad Autonoma de Buenos Aires
  isOrdenCongelamientoFondos: boolean = false;

  //
  slotsAdviceIn!: number;

  //national limits
  limits!: { day: number; week: number; month: number };

  //holds all the app features received from server;
  features: C_Features;

  //holds the login redirect path after the user has logged
  afterLoginUrl: any;
  afterLoginParamsUrl: any;

  //holds the login redirect path params after the user has logged
  afterLoginParams: any;

  //holds the partner to send to trackers
  partner: string;

  //aux var to know if is necesary to reload myBets
  ticketStatusChanged!: boolean;

  //padding Top footer
  styleFooter: any;

  //ticket_interval_id:number;

  //forces the home to open the login modal on load
  forceToOpenLogin: boolean;

  //refresh time
  tk_rt: number;
  tk_desk_rt: number;
  tk_close: number;
  live_rt: number;
  home_rt: number;
  highlights_rt: number;
  veridasPolling_rt: number;
  modalTicket: any;

  //ticket_interval_id:number;

  //hora de inicio de sesion
  sessionInitDate!: Date;

  //License Type (National, Madrid, Extremadura, PV, Mexico, Colombia, Panama)
  licenseType: LicenseTypes = LicenseTypes.Nacional;
  freeBetIdSelected: string = '';

  gNav!: NavController;
  leftMenuOpen: boolean;
  platforms: Array<string>;
  //lista de banners de la home
  lstBanners: Array<C_Banners> | Array<I_NewCarousel>;
  enableBack: boolean;

  //codePromotion - Se recibe por parámetro en la home y se guarda en global hasta el registro
  codePromotion!: string;

  //defaultPromotion
  defaultPromotion: boolean;

  //value of deposits
  depositsValue: number;

  rootScope: any;

  //Primera carga de la app
  firstLoad: boolean;

  //Video Player window
  videoPlayerWindow!: boolean;
  videoPlayerOnTicket: boolean;

  //Dy
  DyClientApiKey!: string;
  DyClientEngagementUrl!: string;
  DinamicYieldUrl: string;
  RecentGamesUrl: string;

  // Intervalo slots
  timerSlots: any;

  //Colombia renew SelfExclusion
  renewSelfExclusion: boolean;

  //TransactionApiSwitches
  PointofSalePayment: boolean;
  BankAccountPayment: boolean;
  HalCashPayment: boolean;
  BgtCardPayment: boolean;

  PointofSaleDeposit: boolean;
  ElectronicCardDeposit: boolean;
  TeleingreosDeposit: boolean;
  PaynetDeposit: boolean;
  PaySafeCardDeposit: boolean;
  BgtCardDeposit: boolean;
  CRUDoverTransactions: boolean;

  EpgCreditCardEnabled: boolean;

  miniGame: any;
  hasTicket: boolean = true;
  XMLHttpRequest_WITHCREDENTIALS: boolean = false;
  cuotaAccept: string = '2';
  playingMiniGame: boolean = false;
  ///
  ApplicationState: CApplicationState = new CApplicationState();

  valueTown: string;

  loginOpen: boolean;

  nativeIOSVersion: string;
  nativeIOSTouchIdAvailable: boolean;

  predictNumberEvents: number;
  predictBet: boolean;
  enabledPredictBet: boolean;

  isDeposit!: boolean;
  openFromRegister: boolean = false;

  friendsID: string;
  licenseTicketType: string;
  gotorecovery: boolean;

  typeMoreinfo: string;
  headerHeight!: number;
  ///////
  menuOpen: boolean = false;
  backButton: boolean = false;

  //puntos Mundial
  pointsWC: number = 0;
  positionWC: number = 0;

  evoPaymentsidTransaccion: string = '';
  evoPaymentsAmount: string = '';
  evoPaymentsNewButton: boolean = false;

  navigateToSlotsGame: boolean;
  slotsGameType: string = 'slot';
  eventProps: any;

  XTREME_PUSH_APPKEY: string = '60Aau6_eRAqJufjbP6D3sVVIvv6xPMy9'; // Default, Spanish APP Key
  ANDROID_SENDER_ID: string = '509621000857';

  strVersion: string = '';

  //selection method pay
  openSelectionChoose!: number;
  paymentEnablednotSelected: Array<any> = [];

  lobbyImgs: string;
  lobbyJackpotsUrl: string;
  lobbyJackpotsTickersUrl: string;
  urlJackpotServices: string;
  urlLiveGameData: string;
  urlRoyalDegree: string;
  casinoPragmaticId: string;
  casinoPragmaticServerId: string;
  casinoCalendar: any;

  withoutWinlineCard: boolean = true;

  debugMode: boolean = false;

  AppVersion: string = '';

  XPMessageBadge: number = 0;
  gameTypes: C_GameTypes = new C_GameTypes();
  cEvent: C_Event = new C_Event();
  betsData: C_BetsData[] = [];

  AvailableBonuses!: Bonus[];

  ItemSelected: any[] = [];
  UrlStreamingApiBase: string;
  UrlUserServiceApiBase: string;
  UrlUserServiceStandAlone: string;
  UrlBaseLoginServices: string;
  UrlBalanceServiceApiBase!: string;

  //FREEBETS VOUCHERS
  UrlFreebetBonus: string;

  // wallet errors
  walletActive: boolean;

  // Navigation
  navigationDestinyPage: any;

  clabeBanksWithDrawalEnabled: boolean;

  isAlreadyCheckBonusHome = false;
  isMirror = true;

  LoaderInterceptorShowUrl: string[] = [
    'RemoveItem',
    'additembyid',
    'logOff',
    'login/web',
    'GetCountries',
    'getticketsbydate',
    'singleResult',
    'betslip/clear',
    'bonus/voucher',
    'clearOrder',
    'GetCategoriesByLeague',
    'closebet',
    'SummaryPlayerServicesAp'
  ];
  // LoaderInterceptorUrlExceptions: string[] = [
  //   'GetEvents',
  //   'Update',
  //   'GetHighlightsEvents',
  //   'gameTypesData',
  //   'calendar/get',
  //   'singleResult/odds',
  //   'feeds/events',
  //   'api/Overask',
  //   'api.solitics',
  //   'GetHomeLiveEvents',
  //   'newAntiForgeryPartial',
  //   'logoHeaderApp',
  //   'setlanguagecode',
  //   'getOrder',
  //   'GetGamesLive',
  //   'GetSports',
  //   'GetLiveEventsBySportHandle',
  //   'GetCompleteRace',
  //   'getNextRaces',
  //   'getRacesbyPaddock',
  //   'GetHighlightsEvents',
  //   'csbgonlineCodereIdWebApi',
  //   'isfullpromotable',
  //   'GetTrackers',
  //   'dynamicyield',
  //   'CodereIdApi',
  //   'BannersApi',
  //   'CheckReceiveMailMvc',
  //   'assets',
  //   'visualstudio',
  //   'csbgonlinereports/calendar/get',
  //   'SetLanguageAndGetResources',
  //   'setlanguagecode',
  //   'config',
  //   'maps.googleapis',
  //   'getFeatures',
  //   'Keepalive',
  //   'GetUserActiveVouchers',
  //   'transaction',
  //   'getFeatures',
  //   'minimumRequiredVersion',
  //   'GetMBonuses',
  //   'GetHomeLiveEvents',
  //   'GetLobbiesLayout',
  //   'gethighlights',
  //   'SearchPlayerBonuses',
  //   'home/index'
  // ];
  ExceptionInterceptorNoShowError: string[] = [
    '/carousel/templates/',
    'coderesbgonlinesbsbanners.azurewebsites.net',
    'login/web',
    'GetLastPrivacyPoliticsSelfieByRegion'
  ];

  cookies: {
    customOK: boolean;
    analyticsOK: boolean;
    advertismentOK: boolean;
  };

  mvpData: {
    token: string;
    login: string;
    register: string;
    deposit: string;
  };

  HeaderInterceptorUrl: string[] = ['coderenavigationservice'];

  // error-tracker interceptor
  ErrorTrackerInterceptorUrls2Inspect: string[] = ['betslipservice'];
  HttpErrorsThatRestartApp: number[] = [102, 103];
  HttpErrorDescription = 'Http Error';
  // end error-tracker interceptor
  // timeout interceptor
  TimeoutInterceptorUrls2Inspect: string[] = ['betslipservice'];
  EnableTimeoutForBackendCalls = false;
  TimeoutForBackendCallsInMSecs = 5000;
  TimeoutErrorDescription = 'Timeout';
  EnableTraceTimeCall = true;
  // end timeout interceptor
  GetTicketsUrl: string;

  ExceptionInterceptorUrls2ToProcessActions: any = [
    'RemoveTicket',
    'RemoveStake',
    'RemoveSessionStorage',
    'RemoveLocalStorage'
  ];

  EnVivoSportsOrder: string[] = ['Fútbol', 'Tenis', 'Baloncesto'];

  countryAvailable!: boolean;
  gameTypesConfigData: C_GameTypes = new C_GameTypes();

  //ios ion-content scroll enable/disable
  iosScrollHide: boolean;

  private currentPageHashValue = '';

  registry!: string;

  cvuGenerated!: boolean;
  showNavbar = true;

  // payment method
  paymentMethod!: string;
  paymentType!: string;

  // sports
  baseDomain: string;
  navigationServiceBaseUrl: string;
  betslipServiceBaseUrl: string;
  production: boolean;
  SportsMiscUrl: string;
  CodereFantasy: string;
  CodereFantasyOperatorKeyProd: string;
  CodereFantasyHPURL: string;
  // end sports
  constructor(
    // public platform: Platform,
    // public app: App,
    // public location: LocationS,
    public events: EventsService // public keyboard: Keyboard // public columns: ColumnsNavigationService, // public routerService: RouterService,
  ) {
    //DEFAULT VALUES
    this.DomainBase = GLOBALS.DOMAIN;
    this.UrlBase = GLOBALS.URLBASE;
    this.UrlBaseReports = GLOBALS.URLBASE_REPORTS;
    this.UrlBaseCasinoBonuses = GLOBALS.URLBASE_CASINO_BONUSES;
    this.UrlBaseCodereEventBus = GLOBALS.URLBASE_CODERE_EVENT_BUS;
    this.UrlVeridasApiBase = GLOBALS.URLBASE_VERIDAS_API;
    this.UrlStreamingApiBase = GLOBALS.URLBASE_STREAMING;
    this.UrlUserServiceApiBase = GLOBALS.URLBASE_USERSERVICE;
    this.UrlUserServiceStandAlone = GLOBALS.URLBASE_USERSERICE_STAND_ALONE;
    this.UrlRegMx = GLOBALS.URLBASE_REGMX;
    this.UrlRegAR = GLOBALS.URLBASE_REGAR;
    this.UrlRegPBA = GLOBALS.URLBASE_REGPBA;
    this.UrlRegMx = GLOBALS.URLBASE_REGMX;
    this.UrlRegCONew = GLOBALS.URLBASE_REGCONEW;
    this.UrlBaseLoginServices = GLOBALS.URLBASE_LOGINSERVICES;

    this.lobbyImgs = GLOBALS.LOBBY_IMAGES;
    this.lobbyJackpotsUrl = GLOBALS.LOBBY_JACKPOTS;
    this.lobbyJackpotsTickersUrl = GLOBALS.LOBBY_JACKPOTS_TICKERS;
    this.urlJackpotServices = GLOBALS.JACKPOTSSERVICES;
    this.urlLiveGameData = GLOBALS.LIVEGAMEDATA;
    this.urlRoyalDegree = GLOBALS.ROYALDEGREE;
    this.casinoPragmaticId = GLOBALS.CASINO_PRAGMATIC_ID;
    this.casinoPragmaticServerId = GLOBALS.CASINO_PRAGMATIC_SERVER_ID;
    this.DinamicYieldUrl = GLOBALS.DYNAMICYIELD;
    this.RecentGamesUrl = GLOBALS.RECENTGAMES;
    this.casinoCalendar = null;
    this.CodereFantasy = GLOBALS.CODERE_FANTASY;
    this.CodereFantasyHPURL = GLOBALS.CODERE_FANTASY_HP_URL;

    this.clabeBanksWithDrawalEnabled = false;

    this.walletActive = false;

    GLOBALS.FEATURES ? (this.features = GLOBALS.FEATURES) : (this.features = new C_Features());

    if (GLOBALS.URLBASE_TRANSACTIONS_API) {
      this.UrlTransactionsApiBase = GLOBALS.DOMAIN + GLOBALS.URLBASE_TRANSACTIONS_API;
    }
    if (GLOBALS.URLBASE_OPERATIONS_API) {
      this.UrlOperationsApiBase = GLOBALS.DOMAIN + GLOBALS.URLBASE_OPERATIONS_API;
    }
    if (GLOBALS.URLBASE_COMMON_API) {
      this.UrlCommonApiBase = GLOBALS.DOMAIN + GLOBALS.URLBASE_COMMON_API;
    }
    if (GLOBALS.XMLHttpRequest_WITHCREDENTIALS) {
      this.XMLHttpRequest_WITHCREDENTIALS = GLOBALS.XMLHttpRequest_WITHCREDENTIALS;
    }

    if (GLOBALS.URLBASE_MYBETS) {
      this.UrlBaseBettingServiceHub = GLOBALS.URLBASE_MYBETS; //"https://coderesbgonlinebettingservicehub20180308-coderesbgonlinebet.azurewebsites.net"
    } else {
      // this.UrlBaseBettingServiceHub = 'https://coderesbgonlinebettingservicehub20180308112504.azurewebsites.net';
      this.UrlBaseBettingServiceHub = 'http://192.168.1.192:6007';
    }

    if (GLOBALS.FREEBET_BONUS) {
      this.UrlFreebetBonus = GLOBALS.FREEBET_BONUS;
    } else {
      this.UrlFreebetBonus = this.DomainBase + '/CheckReceiveMailMvc/orderform/';
    }
    if (GLOBALS.URLBASE_BALANCESERVICE_API) {
      this.UrlBalanceServiceApiBase = GLOBALS.DOMAIN + GLOBALS.URLBASE_BALANCESERVICE_API;
    }

    //** TODO */
    // this.mainPageApp = 'HomePage';
    // this.mainPageComponent = HomePage;

    //Transaction Api Usage
    this.PointofSalePayment = GLOBALS.TRANSAPI_PointofSalePayment;
    this.BankAccountPayment = GLOBALS.TRANSAPI_BankAccountPayment;
    this.HalCashPayment = GLOBALS.TRANSAPI_HalCashPayment;
    this.BgtCardPayment = GLOBALS.TRANSAPI_BgtCardPayment;

    this.PointofSaleDeposit = GLOBALS.TRANSAPI_PointofSaleDeposit;
    this.ElectronicCardDeposit = GLOBALS.TRANSAPI_ElectronicCardDeposit;
    this.TeleingreosDeposit = GLOBALS.TRANSAPI_TeleingreosDeposit;
    this.PaynetDeposit = GLOBALS.TRANSAPI_TeleingreosDeposit;
    this.PaySafeCardDeposit = GLOBALS.TRANSAPI_PaySafeCardDeposit;
    this.BgtCardDeposit = GLOBALS.TRANSAPI_BgtCardDeposit;
    this.CRUDoverTransactions = GLOBALS.TRANSAPI_CRUDoverTransactions;

    this.browser = GLOBALS.BROWSER != null || GLOBALS.BROWSER != undefined ? GLOBALS.BROWSER.toLowerCase() : '';

    //refresh times
    this.tk_rt = parseInt(GLOBALS.TK_RT) || 5000;
    this.tk_desk_rt = parseInt(GLOBALS.TK_DESK_RT) || 8000;
    this.live_rt = parseInt(GLOBALS.LIVE_RT) || 4000;
    this.home_rt = parseInt(GLOBALS.HOME_RT) || 5000;
    this.tk_close = parseInt(GLOBALS.TK_CLOSE) || 5000;
    this.highlights_rt = parseInt(GLOBALS.HIGHLIGHTS_RT) || 5000;
    this.veridasPolling_rt = parseInt(GLOBALS.VERIDAS_POLLING_RT) || 20000;
    this.enableBack = false;

    //
    this.Idioma = 'es';
    this.oddType = 'dec';
    this.isGeolocalized = false;

    this.EpgCreditCardEnabled = GLOBALS.EPGCREDITCARDENABLED;

    switch (GLOBALS.LICENSE_TYPE) {
      case LicenseTypes.ArgentinaCaba:
        this.oddType = 'dec';
        this.licenseType = LicenseTypes.ArgentinaCaba;
        this.currencyISOCode = 'ARS';
        break;
      case LicenseTypes.ArgentinaMendoza:
        this.oddType = 'dec';
        this.licenseType = LicenseTypes.ArgentinaMendoza;
        this.currencyISOCode = 'ARS';
        break;
      case LicenseTypes.ArgentinaPBA:
        this.oddType = 'dec';
        this.licenseType = LicenseTypes.ArgentinaPBA;
        this.currencyISOCode = 'ARS';
        break;
      case LicenseTypes.Mexico:
        this.oddType = 'ame';
        this.licenseType = LicenseTypes.Mexico;
        this.currencyISOCode = 'MXN';
        break;
      case LicenseTypes.Colombia:
        this.oddType = 'dec';
        this.licenseType = LicenseTypes.Colombia;
        this.currencyISOCode = 'COP';
        break;
      case LicenseTypes.Panama:
        this.oddType = 'ame';
        this.licenseType = LicenseTypes.Panama;
        this.currencyISOCode = 'USD';
        break;
      default:
        this.licenseType = LicenseTypes.Nacional;
        this.currencyISOCode = 'EUR';
    }

    // Si license != 0 seran siempre true
    this.cookies = {
      customOK: GLOBALS.COOKIES ? GLOBALS.COOKIES.customOK : true,
      analyticsOK: GLOBALS.COOKIES ? GLOBALS.COOKIES.analyticsOK : true,
      advertismentOK: GLOBALS.COOKIES ? GLOBALS.COOKIES.advertismentOK : true
    };

    this.partner = '';

    this.mvpData = {
      token: '0',
      login: '0',
      register: '0',
      deposit: '0'
    };

    this.ItemSelected = [];
    this.extraUserData = {
      documentationVerification: false,
      graceTime: false,
      limitsExpired: false,
      professionVerification: true,
      isRevocationPage: false,
      tycSelfie: false,
      csrfControl: null,
      acceptanceComunication: false,
      pptNotVerification: false,
      dniExpired: false,
      haveProfesion: false
    };
    this.comeFromLogin = false;
    this.optinPopupOpen = false;

    //INIT VARS
    this.user = new C_User(false);
    this.isDesktop = GLOBALS.PLATFORM == 'Desktop';
    this.isNative = GLOBALS.CORDOVA == 1;
    this.isMobile = !this.isDesktop; //this.platform.is("mobile")

    //** TODO */
    // this.platforms = this.platform.platforms();
    this.TicketOpened = false;
    this.SportSelected = '';
    this.SportHandleSelected = '';
    this.currentPage = null;
    this.amountBet = 1;
    this.hideBetFooterBar = true;
    this.devicePlatform = GLOBALS.PLATFORM;
    //cacheservicespc
    this.urlBaseBanners = this.deviceSlidersFolder();

    this.afterLoginUrl = null;
    this.forceToOpenLogin = false;

    this.leftMenuOpen = true;

    this.lstBanners = [];

    this.renewSelfExclusion = false;

    //
    this.deviceType = GLOBALS.DEVICE_TYPE;

    //timeout para leer la variable 3 segundos después porque se instancia fuera de Zone
    //y puede tardar
    setTimeout(() => {
      this.deviceType = GLOBALS.DEVICE_TYPE;
      /**
       * TODO
       */
      // if (this.rootScope) this.rootScope.createCookieNative(this.deviceType);
    }, 0);

    this.firstLoad = true;

    switch (this.licenseType) {
      case LicenseTypes.Mexico:
        this.minBetValue = 1;
        break;
      case LicenseTypes.ArgentinaPBA:
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaMendoza:
        this.minBetValue = 100;
        break;
      default:
        this.minBetValue = 0.2;
        break;
    }

    this.defaultPromotion = true;

    this.depositsValue = 0;

    // this.events.subscribe('miniGame:launch', (game: any) => { // **+** Events está deprecado
    //   this.miniGame = game;
    // });

    //init all parameters in ApplicationState to false
    this.valueTown = '';

    this.loginOpen = false;

    this.nativeIOSTouchIdAvailable = true;
    this.nativeIOSVersion = '0';

    this.predictNumberEvents = this.features ? parseInt(this.features.predictNumberEvents) : 5;

    this.predictBet = false;
    this.enabledPredictBet = false;

    this.licenseTicketType = '';
    this.gotorecovery = false;

    this.friendsID = '';
    this.typeMoreinfo = '';

    this.navigateToSlotsGame = false;

    if (GLOBALS.XTREME_PUSH_APPKEY) {
      this.XTREME_PUSH_APPKEY = GLOBALS.XTREME_PUSH_APPKEY;
    }

    if (GLOBALS.ANDROID_SENDER_ID) {
      this.ANDROID_SENDER_ID = GLOBALS.ANDROID_SENDER_ID;
    }

    this.debugMode = !!GLOBALS.DEBUG_MODE;

    this.AppVersion = GLOBALS.APP_VERSION;

    this.videoPlayerOnTicket = false;

    if (this.licenseType === LicenseTypes.Nacional) {
      this.registry = 'Standard';
    } else if (this.licenseType === LicenseTypes.ArgentinaCaba || this.licenseType === LicenseTypes.ArgentinaMendoza) {
      this.registry = 'noRenaper';
    }

    this.iosScrollHide = false;
  }

  deviceSlidersFolder() {
    return GLOBALS.URLBASE_BANNERS + '/cacheservices/home/GetSliderImages';
  }

  changePage(currentPage: any, hashValue: string = null) {
    // TODO
    if (!this.currentPage) return;

    //set partners data
    if (this.SetPartners(currentPage)) {
      this.partner = '';
      sessionStorage.removeItem('partnercodere');
    }
    //this methods is called every time the page changes
    //it is used to show or hide the footer or do any other action associated to page change event
    if (this.currentPage.name === 'LobbyCasinoPage' && currentPage.name !== 'LobbyCasinoPage') {
      //Stop signal
      this.events.publish('LobbyCasinoPage:true');
    }
    // TODO
    // if(this.checkNecessaryRefreshBalance() &&  this.features.balanceCasinoDetails && this.user.logged){
    //   setTimeout(() => {
    //     this.events.publish('forceRefreshBalance');
    //   }, 100);
    // }

    if (this.currentPage.name !== 'LoadingPage') {
      if (
        (this.currentPage.name !== currentPage.name &&
          currentPage.name === 'HomePage' &&
          this.isAlreadyCheckBonusHome === false) ||
        (this.currentPage.name !== currentPage.name && currentPage.name === 'PromotionsPage') ||
        (currentPage.name === 'LobbyCasinoPage' && this.currentPageHashValue !== hashValue)
      ) {
        this.events.publish('changePage:finish');
        currentPage.name === 'HomePage' ? (this.isAlreadyCheckBonusHome = true) : '';
      }
    }
    if (currentPage.name !== 'SlotsGamePage') {
      this.events.publish('finishGameSlots');
    }

    console.info('CHANGE PAGE');
    if (!!hashValue || (currentPage != this.currentPage && this.currentPageHashValue !== hashValue)) {
      this.currentPage = currentPage;

      if (hashValue) {
        this.currentPageHashValue = hashValue;
        // TODO
        //this.location.go('/' + hashValue);
      } else {
        // TODO
        //this.location.go('/' + currentPage.name);
      }

      switch (currentPage.name) {
        case 'HomePage':
        case 'DirectosPage':
        case 'EventoPage':
        case 'EventoMejoraPage':
        case 'HorsesPage':
        case 'HorsesMarketPage':
        case 'MercadosPage':
        case 'NowLiveDetailPage':
        case 'SportCountriesPage':
        case 'Crea-Tu-Apuesta':
        case 'RealMadridPage':
        case 'RiverPlatePage':
        case 'SpecialTeamPage':
        case 'TodayPage':
        case 'SportEventsPage':
        case 'EventoSpecialPage':
          this.hideBetFooterBar = false;
          this.hasTicket = true;
          if (this.ApplicationState.StreamingOn) {
            if (this.isMobile) {
              this.events.publish('videoplayer:close');
            } else {
              this.events.publish('videoplayer:toticket');
            }
          }
          break;
        case 'misApuestasPage':
          this.hideBetFooterBar = true;
          this.hasTicket = false;
          if (this.ApplicationState.StreamingOn) {
            if (this.isMobile) {
              this.events.publish('videoplayer:close');
            } else {
              this.events.publish('videoplayer:toticket');
            }
          }
          break;
        default:
          this.hideBetFooterBar = true;
          this.hasTicket = false;
          if (this.ApplicationState.StreamingOn) {
            this.events.publish('videoplayer:free');
          }

          break;
      }
    }
  }

  LicenseTypeString(license: number): string {
    switch (license) {
      case LicenseTypes.Nacional:
        return 'Nacional';
      case LicenseTypes.Madrid:
        return 'Madrid';
      case LicenseTypes.Mexico:
        return 'Mexico';
      case LicenseTypes.PaisVasco:
        return 'PV';
      case LicenseTypes.Colombia:
        return 'Colombia';
      case LicenseTypes.Panama:
        return 'Panama';
      case LicenseTypes.ArgentinaCaba:
        return 'ArgentinaCaba';
      case LicenseTypes.ArgentinaPBA:
        return 'ArgentinaPBA';
      case LicenseTypes.ArgentinaCordoba:
        return 'Cordoba';
      case LicenseTypes.ArgentinaMendoza:
        return 'ArgentinaMendoza';
      default:
        return '';
    }
  }

  getCuotaAccept(value: any) {
    this.user.customization = new C_Customization(
      value ? value.AutoAcceptOddUpChanges : false,
      value ? value.AutoAcceptOddDownChanges : false
    );
    if (this.user.customization.AutoAcceptOddUpChanges && this.user.customization.AutoAcceptOddDownChanges) {
      this.cuotaAccept = '0';
    } else if (this.user.customization.AutoAcceptOddUpChanges && !this.user.customization.AutoAcceptOddDownChanges) {
      this.cuotaAccept = '1';
    } else if (!this.user.customization.AutoAcceptOddUpChanges && !this.user.customization.AutoAcceptOddDownChanges) {
      this.cuotaAccept = '2';
    }
  }

  setCuotaAccept(value: string) {
    this.cuotaAccept = value;
    switch (value) {
      case '0':
        this.user.customization = new C_Customization(true, true);
        break;
      case '1':
        this.user.customization = new C_Customization(true, false);
        break;
      case '2':
        this.user.customization = new C_Customization(false, false);
        break;
    }
  }

  ApplicationStateToString(): string {
    //reads all the parameters ending with "On" and retuns the string
    let stateStr = '';
    let index = 0;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    for (const propName in vm.ApplicationState) {
      if (propName.indexOf('On') != -1) {
        if (index > 0) {
          stateStr += '&';
        }
        if (vm.ApplicationState[propName]) {
          stateStr += propName;
        } else {
          stateStr += propName.split('On')[0] + 'Off';
        }
        index++;
      }
    }

    return stateStr;
  }

  SetPartners(currentPage) {
    return (
      currentPage.name != 'HomePage' &&
      currentPage.name != 'MvpCoderePage' &&
      currentPage.name != 'RegistroCOPage' &&
      currentPage.name != 'RegistroCONewPage' &&
      currentPage.name != 'CashierPage' &&
      this.partner != ''
    );
  }

  setPaymentMethod(paymentMethod, paymentType) {
    this.paymentMethod = paymentMethod;
    this.paymentType = paymentType;
  }

  checkNecessaryRefreshBalance() {
    const refresh =
      (this.currentPage.name === 'LobbyCasinoPage' &&
        window.location.hash.split('/')[1] === 'CasinoPage' &&
        (this.licenseType === LicenseTypes.Nacional ||
          this.licenseType === LicenseTypes.Colombia ||
          this.licenseType === LicenseTypes.Panama ||
          this.licenseType === LicenseTypes.ArgentinaCaba ||
          this.licenseType === LicenseTypes.ArgentinaMendoza)) ||
      (this.currentPage.name === 'LobbyCasinoPage' &&
        window.location.hash.split('/')[1] === 'JackpotPage' &&
        (this.licenseType === LicenseTypes.Nacional || this.licenseType === LicenseTypes.Colombia)) ||
      (this.currentPage.name === 'SlotsBonusPage' &&
        window.location.hash.split('/')[1] === 'SlotsBonusPage' &&
        (this.licenseType === LicenseTypes.Nacional || this.licenseType === LicenseTypes.Colombia));
    return refresh;
  }
}

class tPage {
  name: PagesNames;
  url: string;
  path: string;
  baseClass: any;
  constructor(name: PagesNames, url: string, path: string, baseClass: any) {
    this.name = name;
    this.url = url;
    this.path = path;
    this.baseClass = baseClass;
  }
}

//eslint-disable-next-line
enum PagesNames {
  Home,
  ultimoMinuto,
  ticketApuestas,
  tecladoApuestas
}
